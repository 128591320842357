import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './Register';
import PasswordResetForm from './PasswordResetForm';
import { useState } from 'react';
import { useStore } from '../../store/index';
import { intl } from '../../lang';
const description = {
	de: {
		title: 'Weltweite Doppel-GTP-4 Plattform | DoubleGTP',
		context:
			'DoubleGTP ist ein weltweiter Anbieter von doppelt so starkem GTP-4 und bietet Zugang zu fortschrittlichen KI-Modellen wie dem doppelten GTP-4, mehrfachem GTP-3.5-Turbo, DALL-E, Whispern,MidJourney und Claude. Wir werden von über 100 Unternehmensentwicklungsteams weltweit vertraut.',
	},
	en: {
		title: 'Global 2X GPT-4 Platform | DoubleGPT',
		context:
			'DoubleGPT is a global provider of double GPT-4, offering access to advanced AI models such as double GPT-4, multiple GPT-3.5-Turbo, DALL-E, Whisper, MidJourney and Claude. We are trusted by over 100 enterprise development teams worldwide.',
	},
	es: {
		title: 'Plataforma mundial de doble capacidad para el modelo generativo preentrenado (GPT) versión 4 | DoubleGPT',
		context:
			'DoubleGPT es un proveedor global del modelo generativo preentrenado versión 4 a doble capacidad y ofrece acceso a modelos avanzados de inteligencia artificial como el doble del modelo generativo preentrenado versión 4 y múltiples versiones del turbo modelo generativo preentrenado versión tres punto cinco , DALL-E , Whisper , MidJourney y Claude . Contamos con la confianza de más cien equipos mundiales dedicados al desarrollo de empresas.',
	},
	fr: {
		title: 'Plateforme mondiale de GPT-4 double | DoubleGPT',
		context:
			"DoubleGPT est un fournisseur mondial de double GPT-4, offrant l'accès à des modèles d'IA avancés tels que le double GPT-4, plusieurs GPT-3.5-Turbo, DALL-E, Whisper, MidJourney et Claude. Nous sommes approuvés par plus de 100 équipes de développement d'entreprise dans le monde entier.",
	},
	ja: {
		title: 'グローバル２倍GPT-4プラットフォーム｜DoubleGPT',
		context:
			'DoubleGPTは、２倍のＧＰＴ－４を提供するグローバル企業で、２倍のＧＰＴ－４や多重の ＧＰＴ－３．５－ターボ、ダリー（DALL-E）、ウィスパー（Whisper）、ミッドジャーニー（MidJourney） クロード (Claude)など先進的な AI モデルへのアクセスを提供しています。私たちは全世界で100以上の企業開発チームから信頼されています.',
	},
	ko: {
		title: '글로벌 2배 GPT-4 플랫폼 | DoubleGPT',
		context:
			'DoubleGPT는 글로벌 2배 GPT-4 제공업체로, 두 배의 GPT-4, 다양한 GPT-3.5-Turbo, DALL-E, Whisper, MidJourney 및 Claude와 같은 고급 AI 모델에 대한 접근을 제공합니다. 전 세계적으로 100개 이상의 기업 개발 팀이 우리를 신뢰하고 있습니다.',
	},
	'zh-tw': {
		title: '全球雙倍GPT-4調用平台｜DoubleGPT',
		context:
			'DoubleGPT 是一家全球雙倍GPT-4提供商，提供雙倍GPT-4、多倍GPT-3.5-Turbo、DALL-E、whisper、MidJourney、Claude等高級AI模型調用，並受到超過100家企業開發團隊的信賴',
	},
	zh: {
		title: '全球双倍GPT-4调用平台｜DoubleGPT',
		context:
			'DoubleGPT 是一家全球双倍GPT-4提供商，提供双倍GPT-4、多倍GPT-3.5-Turbo、DALL-E、whisper、MidJourney、Claude等高级AI模型调用，并受到超过100家企业开发团队的信赖。',
	},
};
const key = localStorage.getItem('lang') ?? 'en';
const LoginWarpper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	.header-container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px;
	}
	.context {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		--color: #e7e7e7;
		.left-box {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 24px;
			flex: 1;
			width: 0;
			min-width: 400px;
			height: 100%;
			padding: 0px 24px;
			background-image: linear-gradient(to right, rgb(225 48 96), rgb(0 71 207));
			box-shadow: 1px 1px 4px var(--color), 1px -1px 4px var(--color), -1px 0px 4px var(--color);
			.logo {
				width: 500px;
			}

			.title {
				font-size: 32px;
				font-weight: 500;
				text-align: center;
			}
		}
		.right-container {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			flex: 1;
			width: 0;
			border-top-right-radius: 0.375rem /* 6px */;
			border-bottom-right-radius: 0.375rem /* 6px */;
			transition: all 0.5s;

			box-shadow: 1px 1px 4px var(--color), 1px -1px 4px var(--color), -1px 0px 4px var(--color);
			.right-form {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-left: 4rem;
				padding-right: 4rem;
				overflow: hidden;
				width: 100%;
				height: 100%;
				.right-item {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
`;
const Login = ({ defaultCurrent }) => {
	const [current, setCurrent] = useState(defaultCurrent ?? 0);
	const { isDark } = useStore();
	return (
		<LoginWarpper>
			<div className='header-container'>
				<Link to='/'>
					<img src={isDark ? './app-logo.png' : './logo-hd.png'} alt='logo' style={{
						height: '32px'
					}} />
				</Link>
			</div>
			<div class='context'>
				<div class='left-box'>
					<img className='logo' src='./app-logo.png' />
					<h1 className='title'>{description[key].title}</h1>
				</div>
				<div class='right-container'>
					<div class='right-form'>
						{current === 0 && (
							<div className='right-item'>
								<LoginForm className='right-item' setCurrent={setCurrent} />
							</div>
						)}
						{current === 1 && (
							<div className='right-item'>
								<RegisterForm className='right-item' setCurrent={setCurrent} />
							</div>
						)}
						{current === 2 && (
							<div className='right-item'>
								<PasswordResetForm className='right-item' setCurrent={setCurrent} />
							</div>
						)}
						<div style={{position: 'absolute', bottom:'10px', display: 'flex',gap: '6px', justifyContent: 'space-between', padding:"4px 0", fontSize:"14px", color: '#52C8FC'}}>
							<Link to='/terms'>{intl.get('components.PasswordResetForm.terms')}</Link>
							|
							<Link to='/privacy'>{intl.get('components.PasswordResetForm.privacy')}</Link>
						</div>
					</div>
				</div>
			</div>
		</LoginWarpper>
	);
};

export default Login;

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/User';

import { API, showSuccess } from '../helpers';
import '../index.css';
import { loadLocales } from '../lang/index';
import { Nav, Avatar, Dropdown, Layout, Switch } from '@douyinfe/semi-ui';
import { IconHelpCircle, IconLanguage } from '@douyinfe/semi-icons'
import { stringToColor } from '../helpers/render';
import { intl } from '../lang';
import { useHeaderMenu } from '../hooks/useMenu';
import styled from 'styled-components';

import { useStore } from '../store'

const NavWarpper = styled.div`
	display: flex;
	align-items: center;
	color: white;
	.semi-navigation-item {
		margin-bottom: 0;
	}
`;
// HeaderBar Buttons
let headerButtons = [
	{
		text: `${intl.get('components.HeaderBar.about')}`,
		itemKey: 'about',
		to: '/about',
		icon: <IconHelpCircle />,
	},
];

if (localStorage.getItem('chat_link')) {
	headerButtons.splice(1, 0, {
		name: `${intl.get('components.HeaderBar.chat')}`,
		to: '/document',
		icon: 'comments',
	});
}

const HeaderBar = () => {
	const [userState, userDispatch] = useContext(UserContext);
	let navigate = useNavigate();

	const [dark, setDark] = useState(false);
	var themeMode = localStorage.getItem('theme-mode');
	const SiderBarButton = useHeaderMenu(true);
	const location = useLocation();

	async function logout() {
		await API.get('/api/user/logout');
		showSuccess(`${intl.get('components.HeaderBar.logout-successful')}`);
		userDispatch({ type: 'logout' });
		localStorage.removeItem('user');
		navigate('/login');
	}

	useEffect(() => {
		if (themeMode === 'dark') {
			switchMode(true);
		}
	}, []);
	const { setItem } = useStore()
	const switchMode = (model) => {
		const body = document.body;
		if (!model) {
			body.removeAttribute('theme-mode');
			setItem?.('theme-mode', 'light')
			localStorage.setItem('theme-mode', 'light');
		} else {
			body.setAttribute('theme-mode', 'dark');
			setItem?.('theme-mode', 'dark')
			localStorage.setItem('theme-mode', 'dark');
		}
		setDark(model);
	};

	const isHome = useMemo(() => {
		const shows = ['/', '/privacy', '/terms']
		return shows.includes(window.location.pathname)
	}, [location])
	return (
		<>
			<Layout>
				<div style={{ width: '100%' }}>
					<Nav
						mode={'horizontal'}
						renderWrapper={({ itemElement, isSubNav, isInSubNav, props }) => {
							const routerMap = {
								about: '/about',
								document: '/document',
								login: '/login',
								register: '/register',

								home: '/',
								channel: '/channel',
								token: '/api-keys',
								redemption: '/redemption',
								topup: '/topup',
								user: '/user',
								log: '/log',
								midjourney: '/midjourney',
								setting: '/setting',
								chat: '/chat',
								detail: '/overview',
							};
							return (
								<Link style={{ textDecoration: 'none' }} to={routerMap[props.itemKey]}>
									{itemElement}
								</Link>
							);
						}}
						{...(isHome
							? {
									items: SiderBarButton,
							  }
							: {})}
						footer={
							<>
								<NavWarpper>
									{!isHome && (
										<Nav.Item>
											<Switch checkedText='🌞' size={'large'} checked={dark} uncheckedText='🌙' onChange={switchMode} />
										</Nav.Item>
									)}
									<Nav.Item itemKey={'lang'}>
										<Dropdown
											position='bottomRight'
											render={
												<Dropdown.Menu>
													<Dropdown.Item onClick={() => loadLocales('en')}>English</Dropdown.Item>
													<Dropdown.Item onClick={() => loadLocales('fr')}>Französisch</Dropdown.Item>
													<Dropdown.Item onClick={() => loadLocales('de')}>Deutsch</Dropdown.Item>
													<Dropdown.Item onClick={() => loadLocales('ja')}>日本語</Dropdown.Item>
													<Dropdown.Item onClick={() => loadLocales('ko')}>한국어</Dropdown.Item>
													<Dropdown.Item onClick={() => loadLocales('es')}>Español</Dropdown.Item>
													<Dropdown.Item onClick={() => loadLocales('zh')}>简体中文</Dropdown.Item>
													<Dropdown.Item onClick={() => loadLocales('zh-tw')}>繁體中文</Dropdown.Item>
												</Dropdown.Menu>
											}
										>
											<span
												style={{
													display: 'flex',
													gap: '8px',
													color: 'var(--semi-color-text-2)',
													fontSize: '16px',
												}}
											>
												<IconLanguage size='large' />
												{intl.get('components.language')}
											</span>
										</Dropdown>
									</Nav.Item>
								</NavWarpper>
								{userState.user ? (
									<>
										<Dropdown
											position='bottomRight'
											render={
												<Dropdown.Menu>
													<Dropdown.Item onClick={logout}>{intl.get('components.HeaderBar.quit')}</Dropdown.Item>
												</Dropdown.Menu>
											}
										>
											<Avatar size='small' color={stringToColor(userState.user.username)} style={{ margin: 4 }}>
												{userState.user.username[0]}
											</Avatar>
											<span>{userState.user.username}</span>
										</Dropdown>
									</>
								) : (
									<>
										<Nav.Item
											style={{
												color: 'var(--semi-color-text-2)',
											}}
											itemKey={'login'}
											text={intl.get('components.login')}
										/>
										<Nav.Item
											style={{
												color: 'var(--semi-color-text-2)',
											}}
											itemKey={'register'}
											text={intl.get('components.register')}
										/>
									</>
								)}
							</>
						}
					></Nav>
				</div>
			</Layout>
		</>
	);
};

export default HeaderBar;

import React, { useEffect, useState } from 'react';
import { Label } from 'semantic-ui-react';
import { API, copy, isAdmin, showError, showSuccess, timestamp2string } from '../helpers';

import { Table, Avatar, Tag, Form, Button, Layout, Select, Popover, Modal, Spin } from '@douyinfe/semi-ui';
import { ITEMS_PER_PAGE } from '../constants';
import { renderNumber, renderQuota, stringToColor } from '../helpers/render';
import {
	IconAt,
	IconHistogram,
	IconGift,
	IconKey,
	IconUser,
	IconLayers,
	IconSetting,
	IconCreditCard,
	IconSemiLogo,
	IconHome,
	IconMore,
} from '@douyinfe/semi-icons';

import { intl } from '../lang';
const { Header } = Layout;

function renderTimestamp(timestamp) {
	return <>{timestamp2string(timestamp)}</>;
}

const colors = [
	'amber',
	'blue',
	'cyan',
	'green',
	'grey',
	'indigo',
	'light-blue',
	'lime',
	'orange',
	'pink',
	'purple',
	'red',
	'teal',
	'violet',
	'yellow',
];

function renderType(type) {
	switch (type) {
		case 1:
			return (
				<Tag color='cyan' size='large'>
					{' '+intl.get("components.LogsTable.pay")+' '}
				</Tag>
			);
		case 2:
			return (
				<Tag color='lime' size='large'>
					{' '+intl.get("components.LogsTable.consume")+' '}
				</Tag>
			);
		case 3:
			return (
				<Tag color='orange' size='large'>
					{' '+intl.get("components.LogsTable.manage")+' '}
				</Tag>
			);
		case 4:
			return (
				<Tag color='purple' size='large'>
					{' '+intl.get("components.LogsTable.system")+' '}
				</Tag>
			);
		default:
			return (
				<Tag color='black' size='large'>
					{' '+intl.get("components.LogsTable.unknown")+' '}
				</Tag>
			);
	}
}

const LogsTable = () => {
	const columns = [
		{
			title: `${intl.get("components.LogsTable.time")}`,
			dataIndex: 'timestamp2string',
		},
		...isAdmin() ? [
			{
				title: `${intl.get("components.LogsTable.channel")}`,
				dataIndex: 'channel',
				className: isAdmin() ? 'tableShow' : 'tableHiddle',
				render: (text, record, index) => {
					return isAdminUser ? (
						record.type === 0 || record.type === 2 ? (
							<div>
								{
									<Tag color={colors[parseInt(text) % colors.length]} size='large'>
										{' '}
										{text}{' '}
									</Tag>
								}
							</div>
						) : (
							<></>
						)
					) : (
						<></>
					);
				},
			},
			{
				title: `${intl.get("components.LogsTable.user")}`,
				dataIndex: 'username',
				className: isAdmin() ? 'tableShow' : 'tableHiddle',
				render: (text, record, index) => {
					return isAdminUser ? (
						<div>
							<Avatar
								size='small'
								color={stringToColor(text)}
								style={{ marginRight: 4 }}
								onClick={() => showUserInfo(record.user_id)}
							>
								{typeof text === 'string' && text.slice(0, 1)}
							</Avatar>
							{text}
						</div>
					) : (
						<></>
					);
				},
			},
		] : [],

		{
			title: `${intl.get("components.LogsTable.token")}`,
			dataIndex: 'token_name',
			render: (text, record, index) => {
				return record.type === 0 || record.type === 2 ? (
					<div>
						<Tag
							color='grey'
							size='large'
							onClick={() => {
								copyText(text);
							}}
						>
							{' '}
							{text}{' '}
						</Tag>
					</div>
				) : (
					<></>
				);
			},
		},
		{
			title: `${intl.get("components.LogsTable.type")}`,
			dataIndex: 'type',
			render: (text, record, index) => {
				return <div>{renderType(text)}</div>;
			},
		},
		{
			title: `${intl.get("components.LogsTable.model")}`,
			dataIndex: 'model_name',
			render: (text, record, index) => {
				return record.type === 0 || record.type === 2 ? (
					<div>
						<Tag
							color={stringToColor(text)}
							size='large'
							onClick={() => {
								copyText(text);
							}}
						>
							{' '}
							{text}{' '}
						</Tag>
					</div>
				) : (
					<></>
				);
			},
		},
		{
			title: `${intl.get("components.LogsTable.hint")}`,
			dataIndex: 'prompt_tokens',
			render: (text, record, index) => {
				return record.type === 0 || record.type === 2 ? <div>{<span> {text} </span>}</div> : <></>;
			},
		},
		{
			title: `${intl.get("components.LogsTable.complement")}`,
			dataIndex: 'completion_tokens',
			render: (text, record, index) => {
				return parseInt(text) > 0 && (record.type === 0 || record.type === 2) ? <div>{<span> {text} </span>}</div> : <></>;
			},
		},
		{
			title: `${intl.get("components.LogsTable.quota")}`,
			dataIndex: 'quota',
			render: (text, record, index) => {
				return record.type === 0 || record.type === 2 ? <div>{renderQuota(text, 6)}</div> : <></>;
			},
		},
		{
			title: `${intl.get("components.LogsTable.content")}`,
			dataIndex: 'content',
		},
	];
	const [logs, setLogs] = useState([]);
	const [showStat, setShowStat] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingStat, setLoadingStat] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [logCount, setLogCount] = useState(ITEMS_PER_PAGE);
	const [logType, setLogType] = useState(0);
	const isAdminUser = isAdmin();
	let now = new Date();
	// 初始化start_timestamp为前一天
	const [inputs, setInputs] = useState({
		username: '',
		token_name: '',
		model_name: '',
		start_timestamp: timestamp2string(now.getTime() / 1000 - 86400),
		end_timestamp: timestamp2string(now.getTime() / 1000 + 3600),
		channel: '',
	});
	const { username, token_name, model_name, start_timestamp, end_timestamp, channel } = inputs;

	const [stat, setStat] = useState({
		quota: 0,
		token: 0,
	});

	const handleInputChange = (value, name) => {
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};

	const getLogSelfStat = async () => {
		let localStartTimestamp = Date.parse(start_timestamp) / 1000;
		let localEndTimestamp = Date.parse(end_timestamp) / 1000;
		let res = await API.get(
			`/api/log/self/stat?type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`
		);
		const { success, message, data } = res.data;
		if (success) {
			setStat(data);
		} else {
			showError(message);
		}
	};

	const getLogStat = async () => {
		let localStartTimestamp = Date.parse(start_timestamp) / 1000;
		let localEndTimestamp = Date.parse(end_timestamp) / 1000;
		let res = await API.get(
			`/api/log/stat?type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel=${channel}`
		);
		const { success, message, data } = res.data;
		if (success) {
			setStat(data);
		} else {
			showError(message);
		}
	};

	const handleEyeClick = async () => {
		setLoadingStat(true);
		if (isAdminUser) {
			await getLogStat();
		} else {
			await getLogSelfStat();
		}
		setShowStat(true);
		setLoadingStat(false);
	};

	const showUserInfo = async (userId) => {
		if (!isAdminUser) {
			return;
		}
		const res = await API.get(`/api/user/${userId}`);
		const { success, message, data } = res.data;
		if (success) {
			Modal.info({
				title: `${intl.get("components.LogsTable.userinfo")}`,
				content: (
					<div style={{ padding: 12 }}>
						<p>{intl.get("components.LogsTable.username") + ": " +data.username}</p>
						<p>{intl.get("components.LogsTable.balance") + ": " +renderQuota(data.quota)}</p>
						<p>{intl.get("components.LogsTable.used-amount") + ": " +renderQuota(data.used_quota)}</p>
						<p>{intl.get("components.LogsTable.requests-count") + ": " +renderNumber(data.request_count)}</p>
					</div>
				),
				centered: true,
			});
		} else {
			showError(message);
		}
	};

	const setLogsFormat = (logs) => {
		for (let i = 0; i < logs.length; i++) {
			logs[i].timestamp2string = timestamp2string(logs[i].created_at);
			logs[i].key = '' + logs[i].id;
		}
		// data.key = '' + data.id
		setLogs(logs);
		setLogCount(logs.length + ITEMS_PER_PAGE);
		// console.log(logCount);
	};

	const loadLogs = async (startIdx) => {
		setLoading(true);

		let url = '';
		let localStartTimestamp = Date.parse(start_timestamp) / 1000;
		let localEndTimestamp = Date.parse(end_timestamp) / 1000;
		if (isAdminUser) {
			url = `/api/log/?p=${startIdx}&type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel=${channel}`;
		} else {
			url = `/api/log/self/?p=${startIdx}&type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`;
		}
		const res = await API.get(url);
		const { success, message, data } = res.data;
		if (success) {
			if (startIdx === 0) {
				setLogsFormat(data);
			} else {
				let newLogs = [...logs];
				newLogs.splice(startIdx * ITEMS_PER_PAGE, data.length, ...data);
				setLogsFormat(newLogs);
			}
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const pageData = logs.slice((activePage - 1) * ITEMS_PER_PAGE, activePage * ITEMS_PER_PAGE);

	const handlePageChange = (page) => {
		setActivePage(page);
		if (page === Math.ceil(logs.length / ITEMS_PER_PAGE) + 1) {
			// In this case we have to load more data and then append them.
			loadLogs(page - 1).then((r) => {});
		}
	};

	const refresh = async () => {
		// setLoading(true);
		setActivePage(1);
		await loadLogs(0);
	};

	const copyText = async (text) => {
		if (await copy(text)) {
			showSuccess(`${intl.get("components.LogsTable.copied")} ${text}`);
		} else {
			// setSearchKeyword(text);
			Modal.error({ title: `${intl.get("components.LogsTable.cannot-copy-to-clipboard-please-copy-manually")}`, content: text });
		}
	};

	useEffect(() => {
		refresh().then();
	}, [logType]);

	return (
		<>
			<Layout>
				<Header>
					<Spin spinning={loadingStat}>
						<h3>
							{intl.get("components.LogsTable.usage-details-total-consumption-amount")}
							<span onClick={handleEyeClick} style={{ cursor: 'pointer', color: 'gray' }}>
								{showStat ? renderQuota(stat.quota) : intl.get("components.LogsTable.click-to-view")}
							</span>
						</h3>
					</Spin>
				</Header>
				<Form layout='horizontal' style={{ marginTop: 10 }}>
					<>
						<Form.Input
							field='token_name'
							label={intl.get("components.LogsTable.token-name")}
							style={{ width: 176 }}
							value={token_name}
							placeholder={intl.get("components.LogsTable.optional-value")}
							name='token_name'
							onChange={(value) => handleInputChange(value, 'token_name')}
						/>
						<Form.Input
							field='model_name'
							label={intl.get("components.LogsTable.model-name")}
							style={{ width: 176 }}
							value={model_name}
							placeholder={intl.get("components.LogsTable.optional-value")}
							name='model_name'
							onChange={(value) => handleInputChange(value, 'model_name')}
						/>
						<Form.DatePicker
							field='start_timestamp'
							label={intl.get("components.LogsTable.start-time")}
							style={{ width: 272 }}
							initValue={start_timestamp}
							value={start_timestamp}
							type='dateTime'
							name='start_timestamp'
							onChange={(value) => handleInputChange(value, 'start_timestamp')}
						/>
						<Form.DatePicker
							field='end_timestamp'
							fluid
							label={intl.get("components.LogsTable.end-time")}
							style={{ width: 272 }}
							initValue={end_timestamp}
							value={end_timestamp}
							type='dateTime'
							name='end_timestamp'
							onChange={(value) => handleInputChange(value, 'end_timestamp')}
						/>
						{isAdminUser && (
							<>
								<Form.Input
									field='channel'
									label={intl.get("components.LogsTable.channel-ID")}
									style={{ width: 176 }}
									value={channel}
									placeholder={intl.get("components.LogsTable.optional-value")}
									name='channel'
									onChange={(value) => handleInputChange(value, 'channel')}
								/>
								<Form.Input
									field='username'
									label={intl.get("components.LogsTable.name-of-user")}
									style={{ width: 176 }}
									value={username}
									placeholder={intl.get("components.LogsTable.optional-value")}
									name='username'
									onChange={(value) => handleInputChange(value, 'username')}
								/>
							</>
						)}
						<Form.Section>
							<Button
								label={intl.get("components.LogsTable.query")}
								type='primary'
								htmlType='submit'
								className='btn-margin-right'
								onClick={refresh}
								loading={loading}
							>
							{intl.get("components.LogsTable.query")}
							</Button>
						</Form.Section>
					</>
				</Form>
				<Table
					style={{ marginTop: 5 }}
					columns={columns}
					dataSource={pageData}
					pagination={{
						currentPage: activePage,
						pageSize: ITEMS_PER_PAGE,
						total: logCount,
						pageSizeOpts: [10, 20, 50, 100],
						onPageChange: handlePageChange,
					}}
				/>
				<Select
					defaultValue='0'
					style={{ width: 120 }}
					onChange={(value) => {
						setLogType(parseInt(value));
					}}
				>
					<Select.Option value='0'>{intl.get("components.LogsTable.all")}</Select.Option>
					<Select.Option value='1'>{intl.get("components.LogsTable.pay")}</Select.Option>
					<Select.Option value='2'>{intl.get("components.LogsTable.consume")}</Select.Option>
					<Select.Option value='3'>{intl.get("components.LogsTable.manage")}</Select.Option>
					<Select.Option value='4'>{intl.get("components.LogsTable.system")}</Select.Option>
				</Select>
			</Layout>
		</>
	);
};

export default LogsTable;

import { useEffect, useState } from 'react'
import { API } from '../../helpers';
import styled from 'styled-components'

function isValidUrl(string) {
  const regex = /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(\/[^\s]*)?$/;
  return regex.test(string);
}

const IframeWapper = styled.div`
	width: 100%;
	height: 100%;
	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}
	`

const Api = () => {
	const [apiPageContent, setApiPageContent] = useState('');
	const initApiPageConten = async () => {
		const { data } = await API.get('/api/api_doc_content')
		console.log(data, 'data')
		if(data.success) {
			setApiPageContent(data.data)
		}
	}
	useEffect(() => {
		initApiPageConten()
	})
	return (
		<IframeWapper>
			{
				!!apiPageContent && isValidUrl(apiPageContent) ? <iframe src={apiPageContent} title='Api' /> : <div dangerouslySetInnerHTML={{
					__html: apiPageContent
				}}></div>
			}
		</IframeWapper>
	)
}

export default Api

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API, copy, isAdmin, showError, showSuccess, showWarning, timestamp2string } from '../helpers';

import { ITEMS_PER_PAGE } from '../constants';
import { renderQuota } from '../helpers/render';
import { Tag, Table, Button, Popover, Form, Modal, Popconfirm, SplitButtonGroup, Dropdown } from '@douyinfe/semi-ui';

import EditToken from '../pages/Token/EditToken';

import { intl } from '../lang';

function renderTimestamp(timestamp) {
	return <>{timestamp2string(timestamp)}</>;
}

function renderStatus(status, model_limits_enabled = false) {
	switch (status) {
		case 1:
			if (model_limits_enabled) {
				return (
					<Tag color='green' size='large'>
						{intl.get('components.TokensTable.enabled-restricted-model')}
					</Tag>
				);
			} else {
				return (
					<Tag color='green' size='large'>
						{intl.get('components.TokensTable.is-active')}
					</Tag>
				);
			}
		case 2:
			return (
				<Tag color='red' size='large'>
					{' ' + intl.get('components.TokensTable.is-disable') + ' '}
				</Tag>
			);
		case 3:
			return (
				<Tag color='yellow' size='large'>
					{' ' + intl.get('components.TokensTable.is-expired') + ' '}
				</Tag>
			);
		case 4:
			return (
				<Tag color='grey' size='large'>
					{' ' + intl.get('components.TokensTable.is-use-up') + ' '}
				</Tag>
			);
		default:
			return (
				<Tag color='black' size='large'>
					{' ' + intl.get('components.TokensTable.unknown-state') + ' '}
				</Tag>
			);
	}
}

const TokensTable = () => {

	const columns = [
		{
			title: `${intl.get('components.TokensTable.name')}`,
			dataIndex: 'name',
		},
		{
			title: `${intl.get('components.TokensTable.status')}`,
			dataIndex: 'status',
			key: 'status',
			render: (text, record, index) => {
				return <div>{renderStatus(text, record.model_limits_enabled)}</div>;
			},
		},
		{
			title: `${intl.get('components.TokensTable.used-credit')}`,
			dataIndex: 'used_quota',
			render: (text, record, index) => {
				return <div>{renderQuota(parseInt(text))}</div>;
			},
		},
		{
			title: `${intl.get('components.TokensTable.residual-credit')}`,
			dataIndex: 'remain_quota',
			render: (text, record, index) => {
				return (
					<div>
						{record.unlimited_quota ? (
							<Tag size={'large'} color={'white'}>
								{intl.get('components.TokensTable.unlimited')}
							</Tag>
						) : (
							<Tag size={'large'} color={'light-blue'}>
								{renderQuota(parseInt(text))}
							</Tag>
						)}
					</div>
				);
			},
		},
		{
			title: `${intl.get('components.TokensTable.created-time')}`,
			dataIndex: 'created_time',
			render: (text, record, index) => {
				return <div>{renderTimestamp(text)}</div>;
			},
		},
		{
			title: `${intl.get('components.TokensTable.expirated-time')}`,
			dataIndex: 'expired_time',
			render: (text, record, index) => {
				return (
					<div>{record.expired_time === -1 ? `${intl.get('components.TokensTable.never-expire')}` : renderTimestamp(text)}</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'operate',
			render: (text, record, index) => (
				<div>
					<Popover content={'sk-' + record.key} style={{ padding: 20 }} position='top'>
						<Button theme='light' type='tertiary' style={{ marginRight: 1 }}>
							{intl.get('components.TokensTable.check')}
						</Button>
					</Popover>
					<Button
						theme='light'
						type='secondary'
						style={{ marginRight: 1 }}
						onClick={async (text) => {
							await copyText('sk-' + record.key);
						}}
					>
						{intl.get('components.TokensTable.copy')}
					</Button>
					<SplitButtonGroup
						style={{ marginRight: 1 }}
						aria-label={intl.get('components.TokensTable.project-action-button-group')}
					>
						<Button
							theme='light'
							style={{ color: 'rgba(var(--semi-teal-7), 1)' }}
							onClick={() => {
								onOpenLink('next', record.key);
							}}
						>
							{intl.get('components.TokensTable.chat')}
						</Button>
					</SplitButtonGroup>
					<Popconfirm
						title={intl.get('components.TokensTable.delete-token')}
						content={intl.get('components.TokensTable.this-modification-will-not-be-reversed')}
						okType={'danger'}
						position={'left'}
						onConfirm={() => {
							manageToken(record.id, 'delete', record).then(() => {
								removeRecord(record.key);
							});
						}}
					>
						<Button theme='light' type='danger' style={{ marginRight: 1 }}>
							{intl.get('components.TokensTable.delete')}
						</Button>
					</Popconfirm>
					{record.status === 1 ? (
						<Button
							theme='light'
							type='warning'
							style={{ marginRight: 1 }}
							onClick={async () => {
								manageToken(record.id, 'disable', record);
							}}
						>
							{intl.get('components.TokensTable.disable')}
						</Button>
					) : (
						<Button
							theme='light'
							type='secondary'
							style={{ marginRight: 1 }}
							onClick={async () => {
								manageToken(record.id, 'enable', record);
							}}
						>
							{intl.get('components.TokensTable.enable')}
						</Button>
					)}
					<Button
						theme='light'
						type='tertiary'
						style={{ marginRight: 1 }}
						onClick={() => {
							setEditingToken(record);
							setShowEdit(true);
						}}
					>
						{intl.get('components.TokensTable.edit')}
					</Button>
				</div>
			),
		},
	];

	const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE);
	const [showEdit, setShowEdit] = useState(false);
	const [tokens, setTokens] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [tokenCount, setTokenCount] = useState(pageSize);
	const [loading, setLoading] = useState(true);
	const [activePage, setActivePage] = useState(1);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [searchToken, setSearchToken] = useState('');
	const [searching, setSearching] = useState(false);
	const [editingToken, setEditingToken] = useState({
		id: undefined,
	});

	const closeEdit = () => {
		setShowEdit(false);
		setTimeout(() => {
			setEditingToken({
				id: undefined,
			});
		}, 500);
	};

	const setTokensFormat = (tokens) => {
		setTokens(tokens);
		if (tokens.length >= pageSize) {
			setTokenCount(tokens.length + pageSize);
		} else {
			setTokenCount(tokens.length);
		}
	};

	let pageData = tokens.slice((activePage - 1) * pageSize, activePage * pageSize);
	const loadTokens = async (startIdx) => {
		setLoading(true);
		const res = await API.get(`/api/token/?p=${startIdx}&size=${pageSize}`);
		const { success, message, data } = res.data;
		if (success) {
			if (startIdx === 0) {
				setTokensFormat(data);
			} else {
				let newTokens = [...tokens];
				newTokens.splice(startIdx * pageSize, data.length, ...data);
				setTokensFormat(newTokens);
			}
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const refresh = async () => {
		await loadTokens(activePage - 1);
	};

	const copyText = async (text) => {
		if (await copy(text)) {
			showSuccess(`${intl.get('components.TokensTable.copied')}`);
		} else {
			// setSearchKeyword(text);
			Modal.error({
				title: `${intl.get('components.TokensTable.cannot-copy-to-clipboard-please-copy-manually')}`,
				content: text,
			});
		}
	};

	const onOpenLink = async (type, key) => {
		let status = localStorage.getItem('status');
		let serverAddress = '';
		if (status) {
			status = JSON.parse(status);
			serverAddress = status.server_address;
		}
		if (serverAddress === '') {
			serverAddress = window.location.origin;
		}
		let encodedServerAddress = encodeURIComponent(serverAddress);
		const chatLink = localStorage.getItem('chat_link');
		let defaultUrl;

		if (chatLink) {
			defaultUrl = chatLink + `/#/?settings={"key":"sk-${key}","url":"${serverAddress}"}`;
		} else {
			showError(`${intl.get('components.TokensTable.administrator-did-not-set-the-chat-link')}`);
			return;
		}
		let url;
		switch (type) {
			case 'ama':
				url = `ama://set-api-key?server=${encodedServerAddress}&key=sk-${key}`;
				break;

			case 'opencat':
				url = `opencat://team/join?domain=${encodedServerAddress}&token=sk-${key}`;
				break;

			default:
				url = defaultUrl;
		}

		window.open(url, '_blank');
	};

	useEffect(() => {
		loadTokens(0)
			.then()
			.catch((reason) => {
				showError(reason);
			});
	}, [pageSize]);

	const removeRecord = (key) => {
		let newDataSource = [...tokens];
		if (key != null) {
			let idx = newDataSource.findIndex((data) => data.key === key);

			if (idx > -1) {
				newDataSource.splice(idx, 1);
				setTokensFormat(newDataSource);
			}
		}
	};

	const manageToken = async (id, action, record) => {
		setLoading(true);
		let data = { id };
		let res;
		switch (action) {
			case 'delete':
				res = await API.delete(`/api/token/${id}/`);
				break;
			case 'enable':
				data.status = 1;
				res = await API.put('/api/token/?status_only=true', data);
				break;
			case 'disable':
				data.status = 2;
				res = await API.put('/api/token/?status_only=true', data);
				break;
		}
		const { success, message } = res.data;
		if (success) {
			showSuccess(`${intl.get('components.TokensTable.operation-completed-successfully')}`);
			let token = res.data.data;
			let newTokens = [...tokens];
			// let realIdx = (activePage - 1) * ITEMS_PER_PAGE + idx;
			if (action === 'delete') {
			} else {
				record.status = token.status;
				// newTokens[realIdx].status = token.status;
			}
			setTokensFormat(newTokens);
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const searchTokens = async () => {
		if (searchKeyword === '' && searchToken === '') {
			// if keyword is blank, load files instead.
			await loadTokens(0);
			setActivePage(1);
			return;
		}
		setSearching(true);
		const res = await API.get(`/api/token/search?keyword=${searchKeyword}&token=${searchToken}`);
		const { success, message, data } = res.data;
		if (success) {
			setTokensFormat(data);
			setActivePage(1);
		} else {
			showError(message);
		}
		setSearching(false);
	};

	const handleKeywordChange = async (value) => {
		setSearchKeyword(value.trim());
	};

	const handleSearchTokenChange = async (value) => {
		setSearchToken(value.trim());
	};

	const sortToken = (key) => {
		if (tokens.length === 0) return;
		setLoading(true);
		let sortedTokens = [...tokens];
		sortedTokens.sort((a, b) => {
			return ('' + a[key]).localeCompare(b[key]);
		});
		if (sortedTokens[0].id === tokens[0].id) {
			sortedTokens.reverse();
		}
		setTokens(sortedTokens);
		setLoading(false);
	};

	const handlePageChange = (page) => {
		setActivePage(page);
		if (page === Math.ceil(tokens.length / pageSize) + 1) {
			// In this case we have to load more data and then append them.
			loadTokens(page - 1).then((r) => {});
		}
	};

	const rowSelection = {
		onSelect: (record, selected) => {},
		onSelectAll: (selected, selectedRows) => {},
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedKeys(selectedRows);
		},
	};

	const handleRow = (record, index) => {
		if (record.status !== 1) {
			return {
				style: {
					background: 'var(--semi-color-disabled-border)',
				},
			};
		} else {
			return {};
		}
	};

	return (
		<>
			<EditToken refresh={refresh} editingToken={editingToken} visiable={showEdit} handleClose={closeEdit}></EditToken>
			<Form layout='horizontal' style={{ marginTop: 10 }} labelPosition={'left'}>
				<Form.Input
					field='keyword'
					label={intl.get('components.TokensTable.keyword')}
					placeholder={intl.get('components.TokensTable.token-name')}
					value={searchKeyword}
					loading={searching}
					onChange={handleKeywordChange}
				/>
				<Form.Input
					field='token'
					label='Key'
					placeholder={intl.get('components.TokensTable.search-key')}
					value={searchToken}
					loading={searching}
					onChange={handleSearchTokenChange}
				/>
				<Button
					label={intl.get('components.TokensTable.query')}
					type='primary'
					htmlType='submit'
					className='btn-margin-right'
					onClick={searchTokens}
					style={{ marginRight: 8 }}
				>
					{intl.get('components.TokensTable.query')}
				</Button>
			</Form>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={pageData}
				pagination={{
					currentPage: activePage,
					pageSize: pageSize,
					total: tokenCount,
					showSizeChanger: true,
					pageSizeOptions: [10, 20, 50, 100],
					formatPageText: (page) =>
						`${intl.get('components.TokensTable.in')} ${page.currentStart} - ${page.currentEnd} ${intl.get(
							'components.TokensTable.middle'
						)} ${tokens.length} ${intl.get('components.TokensTable.record')}`,
					onPageSizeChange: (size) => {
						setPageSize(size);
						setActivePage(1);
					},
					onPageChange: handlePageChange,
				}}
				loading={loading}
				rowSelection={rowSelection}
				onRow={handleRow}
			></Table>
			<Button
				theme='light'
				type='primary'
				style={{ marginRight: 8 }}
				onClick={() => {
					setEditingToken({
						id: undefined,
					});
					setShowEdit(true);
				}}
			>
				{intl.get('components.TokensTable.add-token')}
			</Button>
			<Button
				label={intl.get('components.TokensTable.copy-the-selected-token')}
				type='warning'
				onClick={async () => {
					if (selectedKeys.length === 0) {
						showError(`${intl.get('components.TokensTable.please-select-one-token')}`);
						return;
					}
					let keys = '';
					for (let i = 0; i < selectedKeys.length; i++) {
						keys += selectedKeys[i].name + '    sk-' + selectedKeys[i].key + '\n';
					}
					await copyText(keys);
				}}
			>
				{intl.get('components.TokensTable.copy-the-token')}
			</Button>
		</>
	);
};

export default TokensTable;

import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from './components/Loading';
import User from './pages/User';
import { PrivateRoute } from './components/PrivateRoute';
import NotFound from './pages/NotFound';
import Setting from './pages/Setting';
import EditUser from './pages/User/EditUser';
import { API, getLogo, getSystemName, showError } from './helpers';
import PasswordResetForm from './components/PasswordResetForm';
import GitHubOAuth from './components/GitHubOAuth';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import { UserContext } from './context/User';
import { StatusContext } from './context/Status';
import Channel from './pages/Channel';
import Token from './pages/Token';
import EditChannel from './pages/Channel/EditChannel';
import Redemption from './pages/Redemption';
import TopUp from './pages/TopUp';
import Log from './pages/Log';
import APIDocument from './pages/API';
import Chat from './pages/Chat';
import Midjourney from './pages/Midjourney';
import { intl } from './lang';
import { BrowserRouter } from 'react-router-dom';
import { DefaultLayout, HomeLayout } from './layouts';
import Detail from './pages/Detail';
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import { StoreContext } from './store';

import zh_CN from '@douyinfe/semi-ui/lib/es/locale/source/zh_CN';
import en_US from '@douyinfe/semi-ui/lib/es/locale/source/en_US';
import ko_KR from '@douyinfe/semi-ui/lib/es/locale/source/ko_KR';
import ja_JP from '@douyinfe/semi-ui/lib/es/locale/source/ja_JP';
import zh_TW from '@douyinfe/semi-ui/lib/es/locale/source/zh_TW';
import es from '@douyinfe/semi-ui/lib/es/locale/source/es';
import de from '@douyinfe/semi-ui/lib/es/locale/source/de';
import fr from '@douyinfe/semi-ui/lib/es/locale/source/fr';
import { LocaleProvider } from '@douyinfe/semi-ui';
import Login from './pages/Login';
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));

const keyMap = {
	de,
	en: en_US,
	es,
	fr,
	ja: ja_JP,
	ko: ko_KR,
	'zh-tw': zh_TW,
	zh: zh_CN,
};

const description = {
	de: {
		title: 'Weltweite Doppel-GTP-4 Plattform | DoubleGPT',
		context:
			'DoubleGPT ist eine weltweit führende Plattform für die Aufrufung von KI-Modellen, die sich darauf spezialisiert hat, Dienste für das doppelte Aufrufen von GPT-4 und anderen fortschrittlichen KI-Modellen anzubieten. Unsere Funktion der "Doppelten Aufrufung" ermöglicht es Kunden, das Doppelte der API-Aufrufquoten zum ursprünglichen Preis zu erhalten, was ihre Forschungs- und Produktionsleistung erheblich verbessert. Derzeit vertrauen und nutzen mehr als 280 Unternehmen und individuelle Entwicklerteams unseren Service.',
	},
	en: {
		title: 'Global 2X GPT-4 Platform | DoubleGPT',
		context:
			'DoubleGPT is a leading global AI model invocation platform, focused on providing services for invoking double GPT-4 and other advanced AI models. Our "double invocation" feature means customers can obtain double the API call quota at the original cost, thereby greatly enhancing their research and production efficiency. Currently, over 280 enterprises and individual development teams trust and use our services.',
	},
	es: {
		title: 'Plataforma mundial de doble capacidad para el modelo generativo preentrenado (GPT) versión 4 | DoubleGPT',
		context:
			'DoubleGPT es una plataforma líder mundial en la invocación de modelos de IA, especializada en ofrecer servicios para la invocación del doble de GPT-4 y otros modelos avanzados de IA. Nuestra función de "invocación doble" significa que los clientes pueden obtener el doble de cuota de llamadas API por el costo original, mejorando así significativamente su eficiencia en investigación y producción. Actualmente, más de 280 empresas y equipos de desarrollo individuales confían y utilizan nuestros servicios.',
	},
	fr: {
		title: 'Plateforme mondiale de GPT-4 double | DoubleGPT',
		context:
			"DoubleGPT est une plateforme d'invocation de modèles d'IA de premier plan au niveau mondial, spécialisée dans la fourniture de services pour l'invocation du double de GPT-4 et d'autres modèles d'IA avancés. Notre fonctionnalité d'appels doubles signifie que les clients peuvent obtenir le double du quota d'appels API au coût initial, augmentant ainsi considérablement leur efficacité en recherche et production. Actuellement, plus de 280 entreprises et équipes de développement individuelles font confiance et utilisent nos services.",
	},
	ja: {
		title: 'グローバル２倍GPT-4プラットフォーム｜DoubleGPT',
		context:
			'DoubleGPTは、世界をリードするAIモデル呼び出しプラットフォームであり、2倍のGPT-4およびその他の高度なAIモデルの呼び出しサービスを提供することに特化しています。私たちの「2倍呼び出し」機能は、顧客が元のコストでAPI呼び出しの枠を2倍にすることができることを意味し、それによって研究開発と生産の効率を大幅に向上させることができます。現在、280社以上の企業と個人開発チームが、私たちのサービスを信頼し利用しています。',
	},
	ko: {
		title: '글로벌 2배 GPT-4 플랫폼 | DoubleGPT',
		context:
			'DoubleGPT는 전 세계적으로 선도하는 AI 모델 호출 플랫폼으로, 두 배의 GPT-4 및 기타 고급 AI 모델 호출 서비스를 제공하는 데 집중하고 있습니다. "두 배 호출" 기능은 고객이 원래 비용의 두 배에 해당하는 API 호출 할당량을 얻을 수 있음을 의미하여, 연구 및 생산 효율성을 크게 향상시킵니다. 현재 280개 이상의 기업 및 개인 개발 팀이 우리의 서비스를 신뢰하고 이용하고 있습니다.',
	},
	'zh-tw': {
		title: '全球雙倍GPT-4調用平台｜DoubleGPT',
		context:
			'DoubleGPT是全球領先的AI模型調用平台，專注於提供雙倍GPT-4和其他高級AI模型的調用服務。我們的"雙倍調用"功能意味著客戶可以以原本成本的兩倍獲取API調用配額，從而大大提高他們的研發和生產效能。目前，已有超過280家企業和個人開發團隊信賴並使用我們的服務。',
	},
	zh: {
		title: '全球双倍GPT-4调用平台｜DoubleGPT',
		context:
			'DoubleGPT是全球领先的AI模型调用平台，专注于提供双倍GPT-4和其他高级AI模型的调用服务。我们的"双倍调用"功能意味着客户可以以原本成本的两倍获取API调用配额，从而大大提高他们的研发和生产效能。目前，已有超过280家企业和个人开发团队信赖并使用我们的服务。',
	},
};
const key = localStorage.getItem('lang') ?? 'en';
function App() {
	const [userState, userDispatch] = useContext(UserContext);
	const [statusState, statusDispatch] = useContext(StatusContext);
	const loadUser = () => {
		let user = localStorage.getItem('user');
		if (user) {
			let data = JSON.parse(user);
			userDispatch({ type: 'login', payload: data });
		}
	};
	const loadStatus = async () => {
		const res = await API.get('/api/status');
		const { success, data } = res.data;
		if (success) {
			localStorage.setItem('status', JSON.stringify(data));
			statusDispatch({ type: 'set', payload: data });
			localStorage.setItem('system_name', data.system_name);
			localStorage.setItem('logo', data.logo);
			localStorage.setItem('footer_html', data.footer_html);
			localStorage.setItem('quota_per_unit', data.quota_per_unit);
			localStorage.setItem('display_in_currency', data.display_in_currency);
			localStorage.setItem('enable_drawing', data.enable_drawing);
			localStorage.setItem('enable_data_export', data.enable_data_export);
			localStorage.setItem('data_export_default_time', data.data_export_default_time);
			if (data.chat_link) {
				localStorage.setItem('chat_link', data.chat_link);
			} else {
				localStorage.removeItem('chat_link');
			}
		} else {
			showError(`${intl.get('App.unable-to-connect-server')}`);
		}
	};

	useEffect(() => {
		loadUser();
		loadStatus().then();
		let systemName = getSystemName();
		if (systemName) {
			document.title = systemName;
		}
		let logo = getLogo();
		if (logo) {
			let linkElement = document.querySelector("link[rel~='icon']");
			if (linkElement) {
				linkElement.href = logo;
			}
		}

		// 处理html
		const metaDescription = document.querySelector('meta[name="description"]');
		metaDescription.content = description[key].context;

		const metaDescriptionOg = document.querySelector('meta[property="og:description"]');
		metaDescriptionOg.content = description[key].context;

		const metaDescriptionOgTitle = document.querySelector('meta[property="og:title"]');
		metaDescriptionOgTitle.content = description[key].title;

		const metaDescriptionOgTwitterTitle = document.querySelector('meta[property="twitter:title"]');
		metaDescriptionOgTwitterTitle.content = description[key].title;

		const metaDescriptionTwitterOg = document.querySelector('meta[property="twitter:description"]');
		metaDescriptionTwitterOg.content = description[key].context;

		document.title = description[key].title;
	}, []);

	useEffect(() => {
		var phoneWidth = parseInt(window.screen.width) / 1920;
		const viewport = document.createElement('meta');
		viewport.name = 'viewport';
		viewport.content = `width=device-width, initial-scale=${phoneWidth}`;
		document.head.appendChild(viewport);
		return () => {
			document.head.removeChild(viewport);
		};
	}, []);
	const [store, setStore] = useState({
		'theme-mode': localStorage.getItem('theme-mode')
	});
	useEffect(() => {
		if(localStorage.getItem('theme-mode') === 'dark') {
			document.body.setAttribute('theme-mode', 'dark');
		}
	}, [])
	const setItem = (key, value) => {
		setStore({
			...store,
			[key]: value,
		});
	};
	return (
		<LocaleProvider locale={keyMap[key]}>
			<StoreContext.Provider
				value={{
					store,
					setItem
				}}
			>
				<BrowserRouter>
					<Routes>
						<Route
							path='/'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<HomeLayout>
										<Home />
									</HomeLayout>
								</Suspense>
							}
						/>
						<Route
							path='/privacy'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<HomeLayout>
										<Privacy />
									</HomeLayout>
								</Suspense>
							}
						/>
						<Route
							path='/terms'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<HomeLayout>
										<Terms />
									</HomeLayout>
								</Suspense>
							}
						/>
						<Route
							path='/channel'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Channel />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/channel/edit/:id'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<EditChannel />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/channel/add'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<EditChannel />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/api-keys'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Token />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/redemption'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Redemption />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/user'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<User />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/user/edit/:id'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<EditUser />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/user/edit'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<EditUser />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/user/reset'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<PasswordResetConfirm />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/login'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<Login />
								</Suspense>
							}
						/>
						<Route
							path='/register'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<Login defaultCurrent={1} />
								</Suspense>
							}
						/>
						<Route
							path='/reset'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<PasswordResetForm />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/oauth/github'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<GitHubOAuth />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/setting'
							element={
								<PrivateRoute>
									<Suspense fallback={<Loading></Loading>}>
										<DefaultLayout>
											<Setting />
										</DefaultLayout>
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path='/document'
							element={
								<PrivateRoute>
									<Suspense fallback={<Loading></Loading>}>
										<DefaultLayout>
											<APIDocument />
										</DefaultLayout>
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path='/topup'
							element={
								<PrivateRoute>
									<Suspense fallback={<Loading></Loading>}>
										<DefaultLayout>
											<TopUp />
										</DefaultLayout>
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path='/log'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Log />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/overview'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Detail />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/midjourney'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Midjourney />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/about'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<About />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/chat'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<Chat />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route path='*' element={<NotFound />} />
					</Routes>
				</BrowserRouter>
			</StoreContext.Provider>
		</LocaleProvider>
	);
}

export default App;

import { useState, useEffect, useCallback } from 'react'
import { API } from '../helpers'

export const useRechargeRate = () => {
	const [rechargeRate, setRechargeRate] = useState(2)
	const loadRechargeRate = useCallback( async () => {
		const { data } = await API.get('/api/user/topupRatio')
		if(data.message) {
			setRechargeRate(data.data)
		}
	}, [])
	useEffect(() => {
		loadRechargeRate()
	}, [loadRechargeRate])

	return {
		rechargeRate
	}
}

import { Toast } from '@douyinfe/semi-ui';
import { toastConstants } from '../constants';
import React from 'react';
import { toast } from 'react-toastify';

import { intl } from '../lang';

const HTMLToastContent = ({ htmlContent }) => {
	return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
export default HTMLToastContent;
export function isAdmin() {
	let user = localStorage.getItem('user');
	if (!user) return false;
	user = JSON.parse(user);
	return user.role >= 10;
}

export function isRoot() {
	let user = localStorage.getItem('user');
	if (!user) return false;
	user = JSON.parse(user);
	return user.role >= 100;
}

export function getSystemName() {
	let system_name = localStorage.getItem('system_name');
	if (!system_name) return 'New API';
	return system_name;
}

export function getLogo() {
	let logo = localStorage.getItem('logo');
	if (!logo) return '/logo.png';
	return logo;
}

export function getFooterHTML() {
	return localStorage.getItem('footer_html');
}

export async function copy(text) {
	let okay = true;
	try {
		await navigator.clipboard.writeText(text);
	} catch (e) {
		okay = false;
		console.error(e);
	}
	return okay;
}

export function isMobile() {
	return window.innerWidth <= 600;
}

let showErrorOptions = { autoClose: toastConstants.ERROR_TIMEOUT };
let showWarningOptions = { autoClose: toastConstants.WARNING_TIMEOUT };
let showSuccessOptions = { autoClose: toastConstants.SUCCESS_TIMEOUT };
let showInfoOptions = { autoClose: toastConstants.INFO_TIMEOUT };
let showNoticeOptions = { autoClose: false };

if (isMobile()) {
	showErrorOptions.position = 'top-center';
	// showErrorOptions.transition = 'flip';

	showSuccessOptions.position = 'top-center';
	// showSuccessOptions.transition = 'flip';

	showInfoOptions.position = 'top-center';
	// showInfoOptions.transition = 'flip';

	showNoticeOptions.position = 'top-center';
	// showNoticeOptions.transition = 'flip';
}

export function showError(error) {
	if (error.message) {
		if (error.name === 'AxiosError') {
			switch (error.response.status) {
				case 401:
					toast.error(`${intl.get('no-login-or-login-has-expired-please-log-in-again')}`, showErrorOptions);
					window.location.href = '/login?expired=true';
					break;
				case 429:
					Toast.error(`${intl.get('helpers.utils.error-too-many-requests-please-try-again-later')}`);
					break;
				case 500:
					Toast.error(`${intl.get('helpers.utils.error-the-server-has-an-internal-error')}`);
					break;
				case 405:
					Toast.info(`${intl.get('helpers.utils.this-site-is-for-demonstration-purposes-only')}`);
					break;
				default:
					Toast.error(`${intl.get('helpers.utils.error')}` + error.message);
			}
			return;
		}
		Toast.error(`${intl.get('helpers.utils.error')}` + error.message);
	} else {
		Toast.error(`${intl.get('helpers.utils.error')}` + error);
	}
}

export function showWarning(message) {
	Toast.warning(message);
}

export function showSuccess(message) {
	Toast.success(message);
}

export function showInfo(message) {
	Toast.info(message);
}

export function showNotice(message, isHTML = false) {
	if (isHTML) {
		toast(<HTMLToastContent htmlContent={message} />, showNoticeOptions);
	} else {
		Toast.info(message);
	}
}

export function openPage(url) {
	window.open(url);
}

export function removeTrailingSlash(url) {
	if (url.endsWith('/')) {
		return url.slice(0, -1);
	} else {
		return url;
	}
}

export function timestamp2string(timestamp) {
	let date = new Date(timestamp * 1000);
	let year = date.getFullYear().toString();
	let month = (date.getMonth() + 1).toString();
	let day = date.getDate().toString();
	let hour = date.getHours().toString();
	let minute = date.getMinutes().toString();
	let second = date.getSeconds().toString();
	if (month.length === 1) {
		month = '0' + month;
	}
	if (day.length === 1) {
		day = '0' + day;
	}
	if (hour.length === 1) {
		hour = '0' + hour;
	}
	if (minute.length === 1) {
		minute = '0' + minute;
	}
	if (second.length === 1) {
		second = '0' + second;
	}
	return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
}

export function timestamp2string1(timestamp, dataExportDefaultTime = 'hour') {
	let date = new Date(timestamp * 1000);
	// let year = date.getFullYear().toString();
	let month = (date.getMonth() + 1).toString();
	let day = date.getDate().toString();
	let hour = date.getHours().toString();
	if (month.length === 1) {
		month = '0' + month;
	}
	if (day.length === 1) {
		day = '0' + day;
	}
	if (hour.length === 1) {
		hour = '0' + hour;
	}
	let str = month + '-' + day;
	if (dataExportDefaultTime === 'hour') {
		str += ' ' + hour + ':00';
	} else if (dataExportDefaultTime === 'week') {
		let nextWeek = new Date(timestamp * 1000 + 6 * 24 * 60 * 60 * 1000);
		let nextMonth = (nextWeek.getMonth() + 1).toString();
		let nextDay = nextWeek.getDate().toString();
		if (nextMonth.length === 1) {
			nextMonth = '0' + nextMonth;
		}
		if (nextDay.length === 1) {
			nextDay = '0' + nextDay;
		}
		str += ' - ' + nextMonth + '-' + nextDay;
	}
	return str;
}

export function downloadTextAsFile(text, filename) {
	let blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
	let url = URL.createObjectURL(blob);
	let a = document.createElement('a');
	a.href = url;
	a.download = filename;
	a.click();
}

export const verifyJSON = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export function shouldShowPrompt(id) {
	let prompt = localStorage.getItem(`prompt-${id}`);
	return !prompt;
}

export function setPromptShown(id) {
	localStorage.setItem(`prompt-${id}`, 'true');
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { getSystemName, isMobile } from '../helpers';
import '../index.css';
import { Nav, Layout } from '@douyinfe/semi-ui';

import { intl } from '../lang';
import { useMenu } from '../hooks/useMenu';
import { styled } from 'styled-components';

import { useStore } from '../store';

const SiderBarWapper = styled.div`
	height: 100%;
	.semi-navigation-item-selected {
		color: var(--semi-color-primary) !important;
	}
`;

const SiderBar = () => {
	const [selectedKeys, setSelectedKeys] = useState(['home']);
	const systemName = getSystemName();
	// HeaderBar Buttons
	const headerButtons = useMenu();
	const { store } = useStore();
	return (
		<>
			<SiderBarWapper>
				<Layout>
					<div style={{ height: '100%' }}>
						<Nav
							defaultIsCollapsed={isMobile()}
							selectedKeys={selectedKeys}
							renderWrapper={({ itemElement, props }) => {
								const routerMap = {
									home: '/',
									channel: '/channel',
									token: '/api-keys',
									redemption: '/redemption',
									topup: '/topup',
									user: '/user',
									log: '/log',
									midjourney: '/midjourney',
									setting: '/setting',
									about: '/about',
									document: '/document',
									detail: '/overview',
								};
								return (
									<Link style={{ textDecoration: 'none' }} to={routerMap[props.itemKey]}>
										{itemElement}
									</Link>
								);
							}}
							items={headerButtons}
							onSelect={(key) => {
								console.log(key);
								setSelectedKeys([key.itemKey]);
							}}
							// ./logo-hd.png
							header={{
								logo: (
									<Link to='/'>
										<img
											src={store && store['theme-mode'] === 'dark' ? './app-logo.png' : './logo-hd.png'}
											alt='logo'
											style={{ width: '100%' }}
										/>
									</Link>
								),
							}}
						>
							<Nav.Footer
								collapseText={(collapsed) => {
									if (!collapsed) {
										return intl.get('components.SiderBar.put-away-the-sidebar');
									}
								}}
								collapseButton={true}
							></Nav.Footer>
						</Nav>
					</div>
				</Layout>
			</SiderBarWapper>
		</>
	);
};

export default SiderBar;

import React from 'react';
import MjLogsTable from '../../components/MjLogsTable';

const Midjourney = () => (
	<>
		<MjLogsTable />
	</>
);

export default Midjourney;

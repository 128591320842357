import React, {useEffect, useState} from 'react';
import {
    API,
    isMobile,
    shouldShowPrompt,
    showError,
    showInfo,
    showSuccess
} from '../helpers';

import {CHANNEL_OPTIONS, ITEMS_PER_PAGE} from '../constants';
import {renderGroup, renderNumberWithPoint, renderQuota} from '../helpers/render';
import { intl } from '../lang'
import {
    Tag,
    Table,
    Button,
    Form,
    Popconfirm,
    Space,
    Tooltip,
    Switch,
		Popover,
    Typography, InputNumber
} from "@douyinfe/semi-ui";
import EditChannel from "../pages/Channel/EditChannel";

let type2label = undefined;

function renderType(type) {
	if (!type2label) {
		type2label = new Map();
		for (let i = 0; i < CHANNEL_OPTIONS.length; i++) {
			type2label[CHANNEL_OPTIONS[i].value] = CHANNEL_OPTIONS[i];
		}
		type2label[0] = { value: 0, text: `${intl.get('components.ChannelsTable.unknown-type')}`, color: 'grey' };
	}
	return (
		<Tag size='large' color={type2label[type]?.color}>
			{type2label[type]?.text}
		</Tag>
	);
}

// function renderBalance(type, balance) {
// 	switch (type) {
// 		case 1: // OpenAI
// 			return <span>${balance.toFixed(2)}</span>;
// 		case 4: // CloseAI
// 			return <span>¥{balance.toFixed(2)}</span>;
// 		case 8: // 自定义
// 			return <span>${balance.toFixed(2)}</span>;
// 		case 5: // OpenAI-SB
// 			return <span>¥{(balance / 10000).toFixed(2)}</span>;
// 		case 10: // AI Proxy
// 			return <span>{renderNumber(balance)}</span>;
// 		case 12: // API2GPT
// 			return <span>¥{balance.toFixed(2)}</span>;
// 		case 13: // AIGC2D
// 			return <span>{renderNumber(balance)}</span>;
// 		default:
// 			return <span>{intl.get('components.ChannelsTable.nonsupport')}</span>;
// 	}
// }

const ChannelsTable = () => {
	const columns = [
		//{
		//     title: '',
		//     dataIndex: 'checkbox',
		//     className: 'checkbox',
		// },
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: `${intl.get('components.ChannelsTable.columns.name')}`,
			dataIndex: 'name',
		},
		{
			title: `${intl.get('components.ChannelsTable.columns.group')}`,
			dataIndex: 'group',
			render: (text, record, index) => {
				return (
					<div>
						<Space spacing={2}>
							{text.split(',').map((item, index) => {
								return renderGroup(item);
							})}
						</Space>
					</div>
				);
			},
		},
		{
			title: `${intl.get('components.ChannelsTable.columns.type')}`,
			dataIndex: 'type',
			render: (text, record, index) => {
				return <div>{renderType(text)}</div>;
			},
		},
		{
			title: `${intl.get('components.ChannelsTable.columns.status')}`,
			dataIndex: 'status',
			render: (text, record, index) => {
				return <div>{renderStatus(text)}</div>;
			},
		},
		{
			title: `${intl.get('components.ChannelsTable.columns.response-time')}`,
			dataIndex: 'response_time',
			render: (text, record, index) => {
				return <div>{renderResponseTime(text)}</div>;
			},
		},
		{
			title: `${intl.get('components.ChannelsTable.columns.expired-time')}`,
			dataIndex: 'expired_time',
			render: (text, record, index) => {
				return (
					<div>
						<Space spacing={1}>
							<Tooltip content={`${intl.get('components.ChannelsTable.columns.used-credit')}`}>
								<Tag color='white' type='ghost' size='large'>
									{renderQuota(record.used_quota)}
								</Tag>
							</Tooltip>
							<Tooltip
								content={
									`${intl.get('components.ChannelsTable.columns.residual-credit')}` +
									record.balance +
									`，${intl.get('components.ChannelsTable.columns.click-to-update')}`
								}
							>
								<Tag
									color='white'
									type='ghost'
									size='large'
									onClick={() => {
										updateChannelBalance(record);
									}}
								>
									${renderNumberWithPoint(record.balance)}
								</Tag>
							</Tooltip>
						</Space>
					</div>
				);
			},
		},
		{
			title: `${intl.get('components.ChannelsTable.columns.priority')}`,
			dataIndex: 'priority',
			render: (text, record, index) => {
				return (
					<div>
						<InputNumber
							style={{ width: 70 }}
							name='priority'
							onChange={(value) => {
								manageChannel(record.id, 'priority', record, value);
							}}
							defaultValue={record.priority}
							min={-999}
						/>
					</div>
				);
			},
		},
		{
			title: `${intl.get('components.ChannelsTable.weight')}`,
			dataIndex: 'weight',
			render: (text, record, index) => {
				return (
					<div>
						<InputNumber
							style={{ width: 70 }}
							name='weight'
							onChange={(value) => {
								manageChannel(record.id, 'weight', record, value);
							}}
							defaultValue={record.weight}
							min={0}
						/>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: 'operate',
			render: (text, record, index) => (
				<div>
					<Button theme='light' type='primary' style={{ marginRight: 1 }} onClick={() => testChannel(record)}>
						{intl.get('components.ChannelsTable.columns.test')}
					</Button>
					<Popconfirm
						title={intl.get('components.ChannelsTable.columns.delete-this-channel-hint')}
						content={intl.get('components.ChannelsTable.columns.this-modification-will-not-be-reversed')}
						okType={'danger'}
						position={'left'}
						onConfirm={() => {
							manageChannel(record.id, 'delete', record).then(() => {
								removeRecord(record.id);
							});
						}}
					>
						<Button theme='light' type='danger' style={{ marginRight: 1 }}>
							{intl.get('components.ChannelsTable.columns.delete')}
						</Button>
					</Popconfirm>
					{record.status === 1 ? (
						<Button
							theme='light'
							type='warning'
							style={{ marginRight: 1 }}
							onClick={async () => {
								manageChannel(record.id, 'disable', record);
							}}
						>
							{intl.get('components.ChannelsTable.columns.disable')}
						</Button>
					) : (
						<Button
							theme='light'
							type='secondary'
							style={{ marginRight: 1 }}
							onClick={async () => {
								manageChannel(record.id, 'enable', record);
							}}
						>
							{intl.get('components.ChannelsTable.columns.enable')}
						</Button>
					)}
					<Button
						theme='light'
						type='tertiary'
						style={{ marginRight: 1 }}
						onClick={() => {
							setEditingChannel(record);
							setShowEdit(true);
						}}
					>
						{intl.get('components.ChannelsTable.columns.edit')}
					</Button>
				</div>
			),
		},
	];

	const [channels, setChannels] = useState([]);
	const [loading, setLoading] = useState(true);
	const [activePage, setActivePage] = useState(1);
	const [idSort, setIdSort] = useState(false);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [searchGroup, setSearchGroup] = useState('');
	const [searching, setSearching] = useState(false);
	const [updatingBalance, setUpdatingBalance] = useState(false);
	const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE);
	const [showPrompt, setShowPrompt] = useState(shouldShowPrompt('channel-test'));
	const [channelCount, setChannelCount] = useState(pageSize);
	const [groupOptions, setGroupOptions] = useState([]);
	const [showEdit, setShowEdit] = useState(false);
	const [enableBatchDelete, setEnableBatchDelete] = useState(false);
	const [editingChannel, setEditingChannel] = useState({
		id: undefined,
	});
	const [selectedChannels, setSelectedChannels] = useState([]);

	const removeRecord = (id) => {
		let newDataSource = [...channels];
		if (id != null) {
			let idx = newDataSource.findIndex((data) => data.id === id);

			if (idx > -1) {
				newDataSource.splice(idx, 1);
				setChannels(newDataSource);
			}
		}
	};

    const setChannelFormat = (channels) => {
        for (let i = 0; i < channels.length; i++) {
            channels[i].key = '' + channels[i].id;
            let test_models = []
            channels[i].models.split(',').forEach((item, index) => {
                test_models.push({
                    node: 'item',
                    name: item,
                    onClick: () => {
                        testChannel(channels[i], item)
                    }
                })
            })
            channels[i].test_models = test_models
        }
        // data.key = '' + data.id
        setChannels(channels);
        if (channels.length >= pageSize) {
            setChannelCount(channels.length + pageSize);
        } else {
            setChannelCount(channels.length);
        }
    }

	const loadChannels = async (startIdx, pageSize, idSort) => {
		setLoading(true);
		const res = await API.get(`/api/channel/?p=${startIdx}&page_size=${pageSize}&id_sort=${idSort}`);
		const { success, message, data } = res.data;
		if (success) {
			if (startIdx === 0) {
				setChannelFormat(data);
			} else {
				let newChannels = [...channels];
				newChannels.splice(startIdx * pageSize, data.length, ...data);
				setChannelFormat(newChannels);
			}
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const refresh = async () => {
		await loadChannels(activePage - 1, pageSize, idSort);
	};

	useEffect(() => {
		// console.log('default effect')
		const localIdSort = localStorage.getItem('id-sort') === 'true';
		setIdSort(localIdSort);
		loadChannels(0, pageSize, localIdSort)
			.then()
			.catch((reason) => {
				showError(reason);
			});
		fetchGroups().then();
	}, []);

	// useEffect(() => {
	//     console.log('search effect')
	//     searchChannels()
	// }, [searchGroup]);

	// useEffect(() => {
	//     localStorage.setItem('id-sort', idSort + '');
	//     refresh()
	// }, [idSort]);

	const manageChannel = async (id, action, record, value) => {
		let data = { id };
		let res;
		switch (action) {
			case 'delete':
				res = await API.delete(`/api/channel/${id}/`);
				break;
			case 'enable':
				data.status = 1;
				res = await API.put('/api/channel/', data);
				break;
			case 'disable':
				data.status = 2;
				res = await API.put('/api/channel/', data);
				break;
			case 'priority':
				if (value === '') {
					return;
				}
				data.priority = parseInt(value);
				res = await API.put('/api/channel/', data);
				break;
			case 'weight':
				if (value === '') {
					return;
				}
				data.weight = parseInt(value);
				if (data.weight < 0) {
					data.weight = 0;
				}
				res = await API.put('/api/channel/', data);
				break;
		}
		const { success, message } = res.data;
		if (success) {
			showSuccess(`${intl.get('components.ChannelsTable.operation-completed-successfully')}`);
			let channel = res.data.data;
			let newChannels = [...channels];
			if (action === 'delete') {
			} else {
				record.status = channel.status;
			}
			setChannels(newChannels);
		} else {
			showError(message);
		}
	};

	const renderStatus = (status) => {
		switch (status) {
			case 1:
				return (
					<Tag size='large' color='green'>
						{intl.get('components.ChannelsTable.is-active')}
					</Tag>
				);
			case 2:
				return (
					<Popover
						trigger={
							<Tag size='large' color='red'>
								{intl.get('components.ChannelsTable.is-disable')}
							</Tag>
						}
						content={intl.get('components.ChannelsTable.this-channel-is-manually-disabled')}
						basic
					/>
				);
			case 3:
				return (
					<Popover
						trigger={
							<Tag size='large' color='yellow'>
								{intl.get('components.ChannelsTable.is-disable')}
							</Tag>
						}
						content={intl.get('components.ChannelsTable.this-channel-is-automatically-disabled-by-the-program')}
						basic
					/>
				);
			default:
				return (
					<Tag size='large' color='grey'>
						{intl.get('components.ChannelsTable.unknown-state')}
					</Tag>
				);
		}
	};

	const renderResponseTime = (responseTime) => {
		let time = responseTime / 1000;
		time = time.toFixed(2) + ` ${intl.get('components.ChannelsTable.time.second')}`;
		if (responseTime === 0) {
			return (
				<Tag size='large' color='grey'>
					${intl.get('components.ChannelsTable.did-not-test')}
				</Tag>
			);
		} else if (responseTime <= 1000) {
			return (
				<Tag size='large' color='green'>
					{time}
				</Tag>
			);
		} else if (responseTime <= 3000) {
			return (
				<Tag size='large' color='lime'>
					{time}
				</Tag>
			);
		} else if (responseTime <= 5000) {
			return (
				<Tag size='large' color='yellow'>
					{time}
				</Tag>
			);
		} else {
			return (
				<Tag size='large' color='red'>
					{time}
				</Tag>
			);
		}
	};

	const searchChannels = async (searchKeyword, searchGroup) => {
		if (searchKeyword === '' && searchGroup === '') {
			// if keyword is blank, load files instead.
			await loadChannels(0, pageSize, idSort);
			setActivePage(1);
			return;
		}
		setSearching(true);
		const res = await API.get(`/api/channel/search?keyword=${searchKeyword}&group=${searchGroup}`);
		const { success, message, data } = res.data;
		if (success) {
			setChannels(data);
			setActivePage(1);
		} else {
			showError(message);
		}
		setSearching(false);
	};

	const testChannel = async (record) => {
		const res = await API.get(`/api/channel/test/${record.id}/`);
		const { success, message, time } = res.data;
		if (success) {
			let newChannels = [...channels];
			record.response_time = time * 1000;
			record.test_time = Date.now() / 1000;
			setChannels(newChannels);
			showInfo(
				`${intl.get('components.ChannelsTable.channel')} ${record.name} ${intl.get(
					'components.ChannelsTable.successful-test'
				)}${intl.get('components.ChannelsTable.takes-time')} ${time.toFixed(2)} ${intl.get('components.time.second')}${intl.get(
					'components.full-stop'
				)}`
			);
		} else {
			showError(message);
		}
	};

	const testAllChannels = async () => {
		const res = await API.get(`/api/channel/test`);
		const { success, message } = res.data;
		if (success) {
			showInfo(`${intl.get('components.ChannelsTable.please-refresh-the-page-to-see-the-results')}`);
		} else {
			showError(message);
		}
	};

	const deleteAllDisabledChannels = async () => {
		const res = await API.delete(`/api/channel/disabled`);
		const { success, message, data } = res.data;
		if (success) {
			showSuccess(
				`${intl.get('components.ChannelsTable.all-disabled-channels-have-been-deleted')}${intl.get(
					'components.ChannelsTable.total'
				)} ${data} ${intl.get('components.ChannelsTable.uni')}`
			);
			await refresh();
		} else {
			showError(message);
		}
	};

	const updateChannelBalance = async (record) => {
		const res = await API.get(`/api/channel/update_balance/${record.id}/`);
		const { success, message, balance } = res.data;
		if (success) {
			record.balance = balance;
			record.balance_updated_time = Date.now() / 1000;
			showInfo(
				`${intl.get('components.ChannelsTable.channel')} ${record.name} ${intl.get(
					'components.ChannelsTable.balance-update-successful'
				)}`
			);
		} else {
			showError(message);
		}
	};

	const updateAllChannelsBalance = async () => {
		setUpdatingBalance(true);
		const res = await API.get(`/api/channel/update_balance`);
		const { success, message } = res.data;
		if (success) {
			showInfo(`${intl.get('components.ChannelsTable.all-enabled-channel-balances-have-been-updated')}`);
		} else {
			showError(message);
		}
		setUpdatingBalance(false);
	};

	const batchDeleteChannels = async () => {
		if (selectedChannels.length === 0) {
			showError(`${intl.get('components.ChannelsTable.please-select-the-channel-to-delete-first')}`);
			return;
		}
		setLoading(true);
		let ids = [];
		selectedChannels.forEach((channel) => {
			ids.push(channel.id);
		});
		const res = await API.post(`/api/channel/batch`, { ids: ids });
		const { success, message, data } = res.data;
		if (success) {
			showSuccess(`${intl.get('components.ChannelsTable.have-deleted')} ${data} ${intl.get('components.ChannelsTable.channels')}`);
			await refresh();
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const fixChannelsAbilities = async () => {
		const res = await API.post(`/api/channel/fix`);
		const { success, message, data } = res.data;
		if (success) {
			showSuccess(`${intl.get('components.ChannelsTable.recovered')} ${data} ${intl.get('components.ChannelsTable.channels')}`);
			await refresh();
		} else {
			showError(message);
		}
	};

	let pageData = channels.slice((activePage - 1) * pageSize, activePage * pageSize);

	const handlePageChange = (page) => {
		setActivePage(page);
		if (page === Math.ceil(channels.length / pageSize) + 1) {
			// In this case we have to load more data and then append them.
			loadChannels(page - 1, pageSize, idSort).then((r) => {});
		}
	};

	const handlePageSizeChange = async (size) => {
		setPageSize(size);
		setActivePage(1);
		loadChannels(0, size, idSort)
			.then()
			.catch((reason) => {
				showError(reason);
			});
	};

	const fetchGroups = async () => {
		try {
			let res = await API.get(`/api/group/`);
			// add 'all' option
			// res.data.data.unshift('all');
			setGroupOptions(
				res.data.data.map((group) => ({
					label: group,
					value: group,
				}))
			);
		} catch (error) {
			showError(error.message);
		}
	};

	const closeEdit = () => {
		setShowEdit(false);
	};

	const handleRow = (record, index) => {
		if (record.status !== 1) {
			return {
				style: {
					background: 'var(--semi-color-disabled-border)',
				},
			};
		} else {
			return {};
		}
	};

	return (
		<>
			<EditChannel refresh={refresh} visible={showEdit} handleClose={closeEdit} editingChannel={editingChannel} />
			<Form
				onSubmit={() => {
					searchChannels(searchKeyword, searchGroup);
				}}
				labelPosition='left'
			>
				<div style={{ display: 'flex' }}>
					<Space>
						<Form.Input
							field='search'
							label={intl.get('components.ChannelsTable.keyword')}
							placeholder={intl.get('components.ChannelsTable.keyword-placeholder')}
							value={searchKeyword}
							loading={searching}
							onChange={(v) => {
								setSearchKeyword(v.trim());
							}}
						/>
						<Form.Select
							field='group'
							label={intl.get('components.ChannelsTable.group')}
							optionList={groupOptions}
							onChange={(v) => {
								setSearchGroup(v);
								searchChannels(searchKeyword, v);
							}}
						/>
					</Space>
				</div>
			</Form>
			<div style={{ marginTop: 10, display: 'flex' }}>
				<Space>
					<Space>
						<Typography.Text strong>{intl.get('components.ChannelsTable.sort-by-ID')}</Typography.Text>
						<Switch
							checked={idSort}
							label={intl.get('components.ChannelsTable.sort-by-ID')}
							uncheckedText={intl.get('components.ChannelsTable.off')}
							aria-label={intl.get('components.ChannelsTable.whether-to-sort-by-ID')}
							onChange={(v) => {
								localStorage.setItem('id-sort', v + '');
								setIdSort(v);
								loadChannels(0, pageSize, v)
									.then()
									.catch((reason) => {
										showError(reason);
									});
							}}
						></Switch>
					</Space>
				</Space>
			</div>

			<Table
				columns={columns}
				dataSource={pageData}
				pagination={{
					currentPage: activePage,
					pageSize: pageSize,
					total: channelCount,
					pageSizeOpts: [10, 20, 50, 100],
					showSizeChanger: true,
					formatPageText: (page) => '',
					onPageSizeChange: (size) => {
						handlePageSizeChange(size).then();
					},
					onPageChange: handlePageChange,
				}}
				loading={loading}
				onRow={handleRow}
			/>
			<div
				style={{
					display: isMobile() ? '' : 'flex',
					marginTop: isMobile() ? 0 : -45,
					zIndex: 999,
					position: 'relative',
					pointerEvents: 'none',
				}}
			>
				<Space style={{ pointerEvents: 'auto' }}>
					<Button
						theme='light'
						type='primary'
						style={{ marginRight: 8 }}
						onClick={() => {
							setEditingChannel({
								id: undefined,
							});
							setShowEdit(true);
						}}
					>
						{intl.get('components.ChannelsTable.add-channel')}
					</Button>
					<Popconfirm
						title={intl.get('components.ChannelsTable.yes')}
						okType={'warning'}
						onConfirm={testAllChannels}
						position={isMobile() ? 'top' : ''}
					>
						<Button theme='light' type='warning' style={{ marginRight: 8 }}>
							{intl.get('components.ChannelsTable.tests-all-enabled-channels')}
						</Button>
					</Popconfirm>
					<Popconfirm title={intl.get('components.ChannelsTable.yes')} okType={'secondary'} onConfirm={updateAllChannelsBalance}>
						<Button theme='light' type='secondary' style={{ marginRight: 8 }}>
							{intl.get('components.ChannelsTable.update-all-enabled-channel-balances')}
						</Button>
					</Popconfirm>
					<Popconfirm
						title={intl.get('components.ChannelsTable.are-you-sure-you-want-to-delete-disabled-channels')}
						content={intl.get('components.ChannelsTable.this-modification-will-not-be-reversed')}
						okType={'danger'}
						onConfirm={deleteAllDisabledChannels}
					>
						<Button theme='light' type='danger' style={{ marginRight: 8 }}>
							{intl.get('components.ChannelsTable.deleting-a-disabled-channel')}
						</Button>
					</Popconfirm>
				</Space>
			</div>
			<div style={{ marginTop: 20 }}>
				<Space>
					<Typography.Text strong>{intl.get('components.ChannelsTable.enabling-batch-deletion')}</Typography.Text>
					<Switch
						label={intl.get('components.ChannelsTable.enabling-batch-deletion')}
						uncheckedText={intl.get('components.ChannelsTable.off')}
						aria-label={intl.get('components.ChannelsTable.whether-to-enable-batch-deletion')}
						onChange={(v) => {
							setEnableBatchDelete(v);
						}}
					></Switch>
					<Popconfirm
						title={intl.get('components.ChannelsTable.are-you-sure-you-want-to-delete-the-selected-channel')}
						content={intl.get('components.ChannelsTable.this-modification-will-not-be-reversed')}
						okType={'danger'}
						onConfirm={batchDeleteChannels}
						disabled={!enableBatchDelete}
						position={'top'}
					>
						<Button disabled={!enableBatchDelete} theme='light' type='danger' style={{ marginRight: 8 }}>
						{intl.get('components.ChannelsTable.deletes-the-selected-channel')}
						</Button>
					</Popconfirm>
					<Popconfirm
						title={intl.get('components.ChannelsTable.are-you-sure-you-want-to-fix-database-consistency')}
						content={intl.get('components.ChannelsTable.use-only-when-there-is-a-problem-with-the-database')}
						okType={'warning'}
						onConfirm={fixChannelsAbilities}
						position={'top'}
					>
						<Button theme='light' type='secondary' style={{ marginRight: 8 }}>
						{intl.get('components.ChannelsTable.repairing-database-consistency')}
						</Button>
					</Popconfirm>
				</Space>
			</div>
		</>
	);
};

export default ChannelsTable;

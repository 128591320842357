import React, { useEffect, useState } from 'react';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import { renderQuota } from '../../helpers/render';
import { Layout, Card, Button, Form, Divider, Space, Modal } from '@douyinfe/semi-ui';
import Title from '@douyinfe/semi-ui/lib/es/typography/title';
import styled from 'styled-components';
import { intl } from '../../lang';
import { useRechargeRate } from '../../hooks';

const FlexBetween = styled.div`
	display: flex;
	justify-content: space-between;
`;

const TopUp = () => {
	const [redemptionCode, setRedemptionCode] = useState('');
	const [topUpCode] = useState('');
	const [topUpCount, setTopUpCount] = useState(0);
	const [amount, setAmount] = useState(0.0);
	const [topUpLink, setTopUpLink] = useState('');
	const [userQuota, setUserQuota] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [open, setOpen] = useState(false);
	const [payWay, setPayWay] = useState('');

	const topUp = async () => {
		if (redemptionCode === '') {
			showInfo(`${intl.get('pages.TopUp.index.please-enter-the-redemption-code')}`);
			return;
		}
		setIsSubmitting(true);
		try {
			const res = await API.post('/api/user/topup', {
				key: redemptionCode,
			});
			const { success, message, data } = res.data;
			if (success) {
				showSuccess(`${intl.get('pages.TopUp.index.the-exchange-is-successful')}`);
				Modal.success({
					title: `${intl.get('pages.TopUp.index.the-exchange-is-successful')}`,
					content: `${intl.get('pages.TopUp.index.successful-exchange')}` + renderQuota(data),
					centered: true,
				});
				setUserQuota((quota) => {
					return quota + data;
				});
				setRedemptionCode('');
			} else {
				showError(message);
			}
		} catch (err) {
			showError(`${intl.get('pages.TopUp.index.request-failed')}`);
		} finally {
			setIsSubmitting(false);
		}
	};
	const { rechargeRate } = useRechargeRate();
	const openTopUpLink = () => {
		if (!topUpLink) {
			showError(`${intl.get('pages.TopUp.index.the-super-administrator-did-not-set-the-recharge-link')}`);
			return;
		}
		window.open(topUpLink, '_blank');
	};

	const preTopUp = async (payment) => {
		if (amount === 0) {
			await getAmount();
		}
		setPayWay(payment);
		setOpen(true);
	};

	const onlineTopUp = async () => {
		if (amount === 0) {
			await getAmount();
		}
		setOpen(false);
		try {
			const res = await API.post('/api/user/pay', {
				amount: parseInt(topUpCount),
				top_up_code: topUpCode,
				payment_method: payWay,
				channel: 2
			});
			if (res !== undefined) {
				const { message, data } = res.data;
				// showInfo(message);
				if (message === 'success') {
					let params = data;
					let url = res.data.url;
					let form = document.createElement('form');
					form.action = url;
					form.method = 'GET';
					// 判断是否为safari浏览器
					let isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') < 1;
					if (!isSafari) {
						form.target = '_blank';
					}
					for (let key in params) {
						let input = document.createElement('input');
						input.type = 'hidden';
						input.name = key;
						input.value = params[key];
						form.appendChild(input);
					}
					document.body.appendChild(form);
					form.submit();
					document.body.removeChild(form);
				} else {
					showError(data);
					// setTopUpCount(parseInt(res.data.count));
					// setAmount(parseInt(data));
				}
			} else {
				showError(res);
			}
		} catch (err) {
			console.log(err);
		} finally {
		}
	};

	const getUserQuota = async () => {
		let res = await API.get(`/api/user/self`);
		const { success, message, data } = res.data;
		if (success) {
			setUserQuota(data.quota);
		} else {
			showError(message);
		}
	};

	useEffect(() => {
		let status = localStorage.getItem('status');
		if (status) {
			status = JSON.parse(status);
			if (status.top_up_link) {
				setTopUpLink(status.top_up_link);
			}
		}
		getUserQuota().then();
	}, []);

	const renderAmount = () => {
		// console.log(amount);
		return '$ ' + amount;
	};

	const getAmount = async (value) => {
		if (value === undefined) {
			value = topUpCount;
		}
		try {
			const res = await API.post('/api/user/amount', {
				amount: parseFloat(value),
				top_up_code: topUpCode,
			});
			if (res !== undefined) {
				const { message, data } = res.data;
				// showInfo(message);
				if (message === 'success') {
					setAmount(parseFloat(data));
				} else {
					showError(data);
					// setTopUpCount(parseInt(res.data.count));
					// setAmount(parseInt(data));
				}
			} else {
				showError(res);
			}
		} catch (err) {
			console.log(err);
		} finally {
		}
	};

	const handleCancel = () => {
		setOpen(false);
	};

	return (
		<div>
			<Layout>
				<Layout.Header>
					<h3>{intl.get('pages.TopUp.index.my-wallet')}</h3>
				</Layout.Header>
				<Layout.Content>
					<Modal
						title={intl.get('pages.TopUp.index.are-you-sure-you-want-to-top-up')}
						visible={open}
						onOk={onlineTopUp}
						onCancel={handleCancel}
						maskClosable={false}
						size={'small'}
						centered={true}
					>
						<p
							style={{
								'text-decoration': 'line-through',
								color: ' #adadad',
							}}
						>
							{intl.get('pages.TopUp.index.pay-count')}
							$ {topUpCount}
						</p>
						<p>
							{intl.get('pages.TopUp.index.arrival-amount')}: $ {topUpCount * rechargeRate}
						</p>
						<p>
							{intl.get('pages.TopUp.index.the-amount-actually-paid')}: {renderAmount()}
						</p>
						<p>{intl.get('pages.TopUp.index.do-you-want-to-confirm-the-recharge')}</p>
					</Modal>
					<div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
						<Card style={{ width: '500px', padding: '20px' }}>
							<Title level={3} style={{ textAlign: 'center' }}>
								{intl.get('pages.TopUp.index.balance')} {renderQuota(userQuota, 2, 1)}
							</Title>
							<p
								style={{
									'text-decoration': 'line-through',
									color: ' #adadad',
									textAlign: 'center',
								}}
							>
								{intl.get('pages.TopUp.index.recharge-amount')} {renderQuota(userQuota, 2, 1 / rechargeRate)}
							</p>
							<div style={{ marginTop: 20 }}>
								<Divider>{intl.get('pages.TopUp.index.change-balance')}</Divider>
								<Form>
									<Form.Input
										field={'redemptionCode'}
										label={intl.get('pages.TopUp.index.code')}
										placeholder={intl.get('pages.TopUp.index.code')}
										name='redemptionCode'
										value={redemptionCode}
										onChange={(value) => {
											setRedemptionCode(value);
										}}
									/>
									<Space>
										{topUpLink ? (
											<Button type={'primary'} theme={'solid'} onClick={openTopUpLink}>
												{intl.get('pages.TopUp.index.get-code')}
											</Button>
										) : null}
										<Button type={'warning'} theme={'solid'} onClick={topUp} disabled={isSubmitting}>
											{isSubmitting ? `${intl.get('pages.TopUp.index.changing')}` : `${intl.get('pages.TopUp.index.change')}`}
										</Button>
									</Space>
								</Form>
							</div>
							<div style={{ marginTop: 20 }}>
								<Divider>{intl.get('pages.TopUp.index.destoon-finance-charge')}</Divider>
								<Form>
									<Form.Input
										field={'redemptionCount'}
										label={
											<FlexBetween>
												<span>
													{intl.get('pages.TopUp.index.the-amount-actually-paid')}: {renderAmount()}
												</span>
												<span>
													{intl.get('pages.TopUp.index.arrival-amount')}: $ {topUpCount ? topUpCount * rechargeRate : topUpCount}
												</span>
											</FlexBetween>
										}
										placeholder={intl.get('pages.TopUp.index.pay-count')}
										name='redemptionCount'
										type={'number'}
										defaultValue={topUpCount}
										value={topUpCount}
										onChange={async (value) => {
											setTopUpCount(value);
											await getAmount(value);
										}}
									/>
									<Space>
										<Button
											type={'primary'}
											theme={'solid'}
											onClick={async () => {
												preTopUp('card');
											}}
										>
											{intl.get('pages.TopUp.index.payNow')}
										</Button>
									</Space>
								</Form>
							</div>
						</Card>
					</div>
				</Layout.Content>
			</Layout>
		</div>
	);
};

export default TopUp;

import { useMemo } from 'react';
import {
	IconCalendarClock,
	IconHistogram,
	IconGift,
	IconKey,
	IconUser,
	IconLayers,
	IconCreditCard,
	IconBookOpenStroked,
	IconImage,
	IconSetting,
} from '@douyinfe/semi-icons';
import { isAdmin } from '../helpers';
import { intl } from '../lang';
export const useMenu = (isHeader = false) => {
	const menu = useMemo(() => {
		return [
			{
				text: intl.get('detail'),
				itemKey: 'detail',
				to: '/detail',
				icon: <IconCalendarClock />,
				className: localStorage.getItem('enable_data_export') === 'true' ? 'semi-navigation-item-normal' : 'tableHiddle',
			},
			...(isAdmin()
				? [
						{
							text: intl.get('components.SiderBar.channel'),
							itemKey: 'channel',
							to: '/channel',
							icon: <IconLayers />,
							className: isAdmin() ? 'semi-navigation-item-normal' : 'tableHiddle',
						},
				  ]
				: []),
			{
				text: `${intl.get('components.SiderBar.token')}`,
				itemKey: 'token',
				to: '/api-keys',
				icon: <IconKey />,
			},
			{
				text: `${intl.get('home-index.document')}`,
				itemKey: 'document',
				to: '/document',
				icon: <IconBookOpenStroked />,
				className: localStorage.getItem('chat_link') ? 'semi-navigation-item-normal' : 'tableHiddle',
			},
			...(isAdmin()
				? [
						{
							text: `${intl.get('components.SiderBar.redemption')}`,
							itemKey: 'redemption',
							to: '/redemption',
							icon: <IconGift />,
							className: isAdmin() ? 'semi-navigation-item-normal' : 'tableHiddle',
						},
				  ]
				: []),
			{
				text: `${intl.get('components.SiderBar.topup')}`,
				itemKey: 'topup',
				to: '/topup',
				icon: <IconCreditCard />,
			},

			...(isAdmin()
				? [
						{
							text: `${intl.get('components.SiderBar.user')}`,
							itemKey: 'user',
							to: '/user',
							icon: <IconUser />,
							className: isAdmin() ? 'semi-navigation-item-normal' : 'tableHiddle',
						},
				  ]
				: []),
			{
				text: `${intl.get('components.SiderBar.log')}`,
				itemKey: 'log',
				to: '/log',
				icon: <IconHistogram />,
			},
			{
				text: `${intl.get('components.SiderBar.midjourney')}`,
				itemKey: 'midjourney',
				to: '/midjourney',
				icon: <IconImage />,
			},
			...(!isHeader
				? [
						{
							text: `${intl.get('components.SiderBar.setting')}`,
							itemKey: 'setting',
							to: '/setting',
							icon: <IconSetting />,
						},
				  ]
				: []),
		];
	}, [isHeader]);
	return menu;
};

export const useHeaderMenu = () => {
	const menu = useMemo(() => {
		return [
			{
				itemKey: 'home',
				to: '/',
				icon: (
					<img
						style={{
							height: '38px',
						}}
						src='./app-logo.png'
					/>
				),
			},
			{
				text: intl.get('home-index.case'),
				itemKey: 'case',
				to: '/case',
			},
			{
				text: intl.get('home-index.price'),
				itemKey: 'price',
				to: '/price',
			},
			{
				text: intl.get('home-index.guide'),
				itemKey: 'guide',
				to: '/guide',
			},
			{
				text: intl.get('home-index.document'),
				itemKey: 'document',
				to: '/document ',
			},
			{
				text: intl.get('home-index.support'),
				itemKey: 'support',
				to: '/support ',
			},
		];
	}, [isAdmin]);
	return menu;
};

import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API, copy, isRoot, showError, showInfo, showNotice, showSuccess } from '../helpers';
import Turnstile from 'react-turnstile';
import { UserContext } from '../context/User';
import { onGitHubOAuthClicked } from './utils';
import {
	Avatar,
	Banner,
	Button,
	Card,
	Descriptions,
	Image,
	Input,
	InputNumber,
	Layout,
	Modal,
	Space,
	Tag,
	Typography,
} from '@douyinfe/semi-ui';
import { getQuotaPerUnit, renderQuota, renderQuotaWithPrompt, stringToColor } from '../helpers/render'

import { intl } from '../lang';

const PersonalSetting = () => {
	const [userState, userDispatch] = useContext(UserContext);
	let navigate = useNavigate();

	const [inputs, setInputs] = useState({
		wechat_verification_code: '',
		email_verification_code: '',
		email: '',
		self_account_deletion_confirmation: '',
		set_new_password: '',
		set_new_password_confirmation: '',
	});
	const [status, setStatus] = useState({});
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const [showWeChatBindModal, setShowWeChatBindModal] = useState(false);
	const [showEmailBindModal, setShowEmailBindModal] = useState(false);
	const [showAccountDeleteModal, setShowAccountDeleteModal] = useState(false);
	const [turnstileEnabled, setTurnstileEnabled] = useState(false);
	const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
	const [turnstileToken, setTurnstileToken] = useState('');
	const [loading, setLoading] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [countdown, setCountdown] = useState(30);
	const [affLink, setAffLink] = useState('');
	const [systemToken, setSystemToken] = useState('');
	const [models, setModels] = useState([]);
	const [openTransfer, setOpenTransfer] = useState(false);
	const [transferAmount, setTransferAmount] = useState(0);

	useEffect(() => {
		let status = localStorage.getItem('status');
		if (status) {
			status = JSON.parse(status);
			setStatus(status);
			if (status.turnstile_check) {
				setTurnstileEnabled(true);
				setTurnstileSiteKey(status.turnstile_site_key);
			}
		}
		getUserData().then((res) => {
			console.log(userState);
		});
		loadModels().then();
		getAffLink().then();
		setTransferAmount(getQuotaPerUnit());
	}, []);

	useEffect(() => {
		let countdownInterval = null;
		if (disableButton && countdown > 0) {
			countdownInterval = setInterval(() => {
				setCountdown(countdown - 1);
			}, 1000);
		} else if (countdown === 0) {
			setDisableButton(false);
			setCountdown(30);
		}
		return () => clearInterval(countdownInterval); // Clean up on unmount
	}, [disableButton, countdown]);

	const handleInputChange = (name, value) => {
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};

	const generateAccessToken = async () => {
		const res = await API.get('/api/user/token');
		const { success, message, data } = res.data;
		if (success) {
			setSystemToken(data);
			await copy(data);
			showSuccess(`${intl.get('components.PersonalSetting.the-token-has-been-copied')}`);
		} else {
			showError(message);
		}
	};

	const getAffLink = async () => {
		const res = await API.get('/api/user/aff');
		const { success, message, data } = res.data;
		if (success) {
			let link = `${window.location.origin}/register?aff=${data}`;
			setAffLink(link);
		} else {
			showError(message);
		}
	};

	const getUserData = async () => {
		let res = await API.get(`/api/user/self`);
		const { success, message, data } = res.data;
		if (success) {
			userDispatch({ type: 'login', payload: data });
		} else {
			showError(message);
		}
	};

	const loadModels = async () => {
		let res = await API.get(`/api/user/models`);
		const { success, message, data } = res.data;
		if (success) {
			setModels(data);
			console.log(data);
		} else {
			showError(message);
		}
	};

	const handleAffLinkClick = async (e) => {
		e.target.select();
		await copy(e.target.value);
		showSuccess(`${intl.get('components.PersonalSetting.the-invite-link-has-been-copied')}`);
	};

	const handleSystemTokenClick = async (e) => {
		e.target.select();
		await copy(e.target.value);
		showSuccess(`${intl.get('components.PersonalSetting.the-system-token-has-been-copied')}`);
	};

	const deleteAccount = async () => {
		if (inputs.self_account_deletion_confirmation !== userState.user.username) {
			showError(`${intl.get('components.PersonalSetting.lease-enter-your-account-name-to-confirm-deletion')}`);
			return;
		}

		const res = await API.delete('/api/user/self');
		const { success, message } = res.data;

		if (success) {
			showSuccess(`${intl.get('components.PersonalSetting.account-has-been-deleted')}`);
			await API.get('/api/user/logout');
			userDispatch({ type: 'logout' });
			localStorage.removeItem('user');
			navigate('/login');
		} else {
			showError(message);
		}
	};

	const bindWeChat = async () => {
		if (inputs.wechat_verification_code === '') return;
		const res = await API.get(`/api/oauth/wechat/bind?code=${inputs.wechat_verification_code}`);
		const { success, message } = res.data;
		if (success) {
			showSuccess(`${intl.get('components.PersonalSetting.wechat-account-binding-success')}`);
			setShowWeChatBindModal(false);
		} else {
			showError(message);
		}
	};

	const changePassword = async () => {
		if (inputs.set_new_password !== inputs.set_new_password_confirmation) {
			showError(`${intl.get('components.PersonalSetting.the-two-entered-passwords-are-inconsistent')}`);
			return;
		}
		const res = await API.put(`/api/user/self`, {
			password: inputs.set_new_password,
		});
		const { success, message } = res.data;
		if (success) {
			showSuccess(`{intl.get('components.PersonalSetting.password-changed-successfully')}`);
			setShowWeChatBindModal(false);
		} else {
			showError(message);
		}
		setShowChangePasswordModal(false);
	};

	const transfer = async () => {
		if (transferAmount < getQuotaPerUnit()) {
			showError(`${intl.get('components.PersonalSetting.the-minimum-transfer-amount-is')}` + renderQuota(getQuotaPerUnit()));
			return;
		}
		const res = await API.post(`/api/user/aff_transfer`, {
			quota: transferAmount,
		});
		const { success, message } = res.data;
		if (success) {
			showSuccess(message);
			setOpenTransfer(false);
			getUserData().then();
		} else {
			showError(message);
		}
	};

	const sendVerificationCode = async () => {
		if (inputs.email === '') {
			showError(`${intl.get('components.PersonalSetting.please-enter-email')}`);
			return;
		}
		setDisableButton(true);
		if (turnstileEnabled && turnstileToken === '') {
			showInfo(`${intl.get('components.PersonalSetting.please-try-again-in-a-few-seconds')}`);
			return;
		}
		setLoading(true);
		const res = await API.get(`/api/verification?email=${inputs.email}&turnstile=${turnstileToken}`);
		const { success, message } = res.data;
		if (success) {
			showSuccess(`${intl.get('components.PersonalSetting.reset-email-sent-successfully')}`);
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const bindEmail = async () => {
		if (inputs.email_verification_code === '') {
			showError(`${intl.get('components.PersonalSetting.please-enter-the-email-verification-code')}`);
			return;
		}
		setLoading(true);
		const res = await API.get(`/api/oauth/email/bind?email=${inputs.email}&code=${inputs.email_verification_code}`);
		const { success, message } = res.data;
		if (success) {
			showSuccess(`${intl.get('components.PersonalSetting.email-account-binding-successful')}`);
			setShowEmailBindModal(false);
			userState.user.email = inputs.email;
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const getUsername = () => {
		if (userState.user) {
			return userState.user.username;
		} else {
			return 'null';
		}
	};

	const handleCancel = () => {
		setOpenTransfer(false);
	};

	const copyText = async (text) => {
		if (await copy(text)) {
			showSuccess(`${intl.get('components.PersonalSetting.copied')}: ` + text);
		} else {
			// setSearchKeyword(text);
			Modal.error({
				title: `${intl.get('components.PersonalSetting.cannot-copy-to-clipboard-please-copy-manually')}`,
				content: text,
			});
		}
	};

	return (
		<div>
			<Layout>
				<Layout.Content>
					<Modal
						title={intl.get('components.PersonalSetting.please-enter-the-amount-to-be-transferred')}
						visible={openTransfer}
						onOk={transfer}
						onCancel={handleCancel}
						maskClosable={false}
						size={'small'}
						centered={true}
					>
						<div style={{ marginTop: 20 }}>
							<Typography.Text>{`{intl.get('components.PersonalSetting.available-credit')}${renderQuotaWithPrompt(
								userState?.user?.aff_quota
							)}`}</Typography.Text>
							<Input style={{ marginTop: 5 }} value={userState?.user?.aff_quota} disabled={true}></Input>
						</div>
						<div style={{ marginTop: 20 }}>
							<Typography.Text>
								{`${intl.get('components.PersonalSetting.line-of-transfer')}${renderQuotaWithPrompt(transferAmount)} ${intl.get(
									'components.PersonalSetting.lowest'
								)}` + renderQuota(getQuotaPerUnit())}
							</Typography.Text>
							<div>
								<InputNumber
									min={0}
									style={{ marginTop: 5 }}
									value={transferAmount}
									onChange={(value) => setTransferAmount(value)}
									disabled={false}
								></InputNumber>
							</div>
						</div>
					</Modal>
					<div style={{ marginTop: 20 }}>
						<Card
							title={
								<Card.Meta
									avatar={
										<Avatar size='default' color={stringToColor(getUsername())} style={{ marginRight: 4 }}>
											{typeof getUsername() === 'string' && getUsername().slice(0, 1)}
										</Avatar>
									}
									title={<Typography.Text>{getUsername()}</Typography.Text>}
									description={
										isRoot() ? (
											<Tag color='red'>{intl.get('components.PersonalSetting.administrator')}</Tag>
										) : (
											<Tag color='blue'>{intl.get('components.PersonalSetting.common-user')}</Tag>
										)
									}
								></Card.Meta>
							}
							headerExtraContent={
								<>
									<Space vertical align='start'>
										<Tag color='green'>{'ID: ' + userState?.user?.id}</Tag>
										<Tag color='blue'>{userState?.user?.group}</Tag>
									</Space>
								</>
							}
							footer={
								<Descriptions row>
									<Descriptions.Item itemKey={intl.get('components.PersonalSetting.current-balance')}>
										{renderQuota(userState?.user?.quota)}
									</Descriptions.Item>
									<Descriptions.Item itemKey={intl.get('components.PersonalSetting.historical-consumption')}>
										{renderQuota(userState?.user?.used_quota)}
									</Descriptions.Item>
									<Descriptions.Item itemKey={intl.get('components.PersonalSetting.request-count')}>
										{userState.user?.request_count}
									</Descriptions.Item>
								</Descriptions>
							}
						>
							<Typography.Title heading={6}>{intl.get('components.PersonalSetting.additivity-model')}</Typography.Title>
							<div style={{ marginTop: 10 }}>
								<Space wrap>
									{models.map((model) => (
										<Tag
											key={model}
											color='cyan'
											onClick={() => {
												copyText(model);
											}}
										>
											{model}
										</Tag>
									))}
								</Space>
							</div>
						</Card>
						<Card
							footer={
								<div>
									<Typography.Text>{intl.get('components.PersonalSetting.invite-link')}</Typography.Text>
									<Input style={{ marginTop: 10 }} value={affLink} onClick={handleAffLinkClick} readOnly />
								</div>
							}
						>
							<Typography.Title heading={6}>{intl.get('components.PersonalSetting.invite-message')}</Typography.Title>
							<div style={{ marginTop: 10 }}>
								<Descriptions row>
									<Descriptions.Item itemKey={intl.get('components.PersonalSetting.unused-income')}>
										<span style={{ color: 'rgba(var(--semi-red-5), 1)' }}>{renderQuota(userState?.user?.aff_quota)}</span>
										<Button type={'secondary'} onClick={() => setOpenTransfer(true)} size={'small'} style={{ marginLeft: 10 }}>
											{intl.get('components.PersonalSetting.transfer')}
										</Button>
									</Descriptions.Item>
									<Descriptions.Item itemKey={intl.get('components.PersonalSetting.total-revenue')}>
										{renderQuota(userState?.user?.aff_history_quota)}
									</Descriptions.Item>
									<Descriptions.Item itemKey={intl.get('components.PersonalSetting.invite-count')}>
										{userState?.user?.aff_count}
									</Descriptions.Item>
								</Descriptions>
							</div>
						</Card>
						<Card>
							<Typography.Title heading={6}>{intl.get('components.PersonalSetting.personal-information')}</Typography.Title>
							<div style={{ marginTop: 20 }}>
								<Typography.Text strong>{intl.get('components.PersonalSetting.email')}</Typography.Text>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>
										<Input
											value={
												userState.user && userState.user.email !== ''
													? userState.user.email
													: `${intl.get('components.PersonalSetting.not-bind')}`
											}
											readonly={true}
										></Input>
									</div>
									<div>
										<Button
											onClick={() => {
												setShowEmailBindModal(true);
											}}
											disabled={userState.user && userState.user.email !== ''}
										>
											{intl.get('components.PersonalSetting.bind-email')}
										</Button>
									</div>
								</div>
							</div>
							<div style={{ marginTop: 10 }}>
								<Typography.Text strong>{intl.get('components.PersonalSetting.WeChat')}</Typography.Text>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>
										<Input
											value={
												userState.user && userState.user.wechat_id !== ''
													? `${intl.get('components.PersonalSetting.is-bind')}`
													: `${intl.get('components.PersonalSetting.not-bind')}`
											}
											readonly={true}
										></Input>
									</div>
									<div>
										<Button disabled={(userState.user && userState.user.wechat_id !== '') || !status.wechat_login}>
											{status.wechat_login
												? `${intl.get('components.PersonalSetting.binding')}`
												: `${intl.get('components.PersonalSetting.not-enabled')}`}
										</Button>
									</div>
								</div>
							</div>
							<div style={{ marginTop: 10 }}>
								<Typography.Text strong>GitHub</Typography.Text>
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div>
										<Input
											value={
												userState.user && userState.user.github_id !== ''
													? userState.user.github_id
													: `${intl.get('components.PersonalSetting.not-bind')}`
											}
											readonly={true}
										></Input>
									</div>
									<div>
										<Button
											onClick={() => {
												onGitHubOAuthClicked(status.github_client_id);
											}}
											disabled={(userState.user && userState.user.github_id !== '') || !status.github_oauth}
										>
											{status.github_oauth
												? `${intl.get('components.PersonalSetting.binding')}`
												: `${intl.get('components.PersonalSetting.not-enabled')}`}
										</Button>
									</div>
								</div>
							</div>

							<div style={{ marginTop: 10 }}>
								<Space>
									<Button onClick={generateAccessToken}>
										{intl.get('components.PersonalSetting.generate-a-system-access-token')}
									</Button>
									<Button
										onClick={() => {
											setShowChangePasswordModal(true);
										}}
									>
										{intl.get('components.PersonalSetting.change-password')}
									</Button>
									<Button
										type={'danger'}
										onClick={() => {
											setShowAccountDeleteModal(true);
										}}
									>
										{intl.get('components.PersonalSetting.delete-personal-account')}
									</Button>
								</Space>

								{systemToken && (
									<Input readOnly value={systemToken} onClick={handleSystemTokenClick} style={{ marginTop: '10px' }} />
								)}
								{status.wechat_login && (
									<Button
										onClick={() => {
											setShowWeChatBindModal(true);
										}}
									>
										{intl.get('components.PersonalSetting.bind-wechat-account')}
									</Button>
								)}
								<Modal
									onCancel={() => setShowWeChatBindModal(false)}
									// onOpen={() => setShowWeChatBindModal(true)}
									visible={showWeChatBindModal}
									size={'mini'}
								>
									<Image src={status.wechat_qrcode} />
									<div style={{ textAlign: 'center' }}>
										<p>{intl.get('components.PersonalSetting.scan-code-to-follow-the-ems-cnpl')}</p>
									</div>
									<Input
										placeholder={intl.get('components.PersonalSetting.auth-code')}
										name='wechat_verification_code'
										value={inputs.wechat_verification_code}
										onChange={(v) => handleInputChange('wechat_verification_code', v)}
									/>
									<Button color='' fluid size='large' onClick={bindWeChat}>
										{intl.get('components.PersonalSetting.binding')}
									</Button>
								</Modal>
							</div>
						</Card>
						<Modal
							onCancel={() => setShowEmailBindModal(false)}
							// onOpen={() => setShowEmailBindModal(true)}
							onOk={bindEmail}
							visible={showEmailBindModal}
							size={'small'}
							centered={true}
							maskClosable={false}
						>
							<Typography.Title heading={6}>{intl.get('components.PersonalSetting.bind-email-address')}</Typography.Title>
							<div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
								<Input
									fluid
									placeholder={intl.get('components.PersonalSetting.enter-your-email-address')}
									onChange={(value) => handleInputChange('email', value)}
									name='email'
									type='email'
								/>
								<Button onClick={sendVerificationCode} disabled={disableButton || loading}>
									{disableButton
										? `${intl.get('components.PersonalSetting.resend')}(${countdown})`
										: `${intl.get('components.PersonalSetting.get-code')}`}
								</Button>
							</div>
							<div style={{ marginTop: 10 }}>
								<Input
									fluid
									placeholder={intl.get('components.PersonalSetting.auth-code')}
									name='email_verification_code'
									value={inputs.email_verification_code}
									onChange={(value) => handleInputChange('email_verification_code', value)}
								/>
							</div>
							{turnstileEnabled ? (
								<Turnstile
									sitekey={turnstileSiteKey}
									onVerify={(token) => {
										setTurnstileToken(token);
									}}
								/>
							) : (
								<></>
							)}
						</Modal>
						<Modal
							onCancel={() => setShowAccountDeleteModal(false)}
							visible={showAccountDeleteModal}
							size={'small'}
							centered={true}
							onOk={deleteAccount}
						>
							<div style={{ marginTop: 20 }}>
								<Banner
									type='danger'
									description={intl.get('components.PersonalSetting.you-are-deleting-your-account')}
									closeIcon={null}
								/>
							</div>
							<div style={{ marginTop: 20 }}>
								<Input
									placeholder={`${intl.get('components.PersonalSetting.enter-your-account-name')} ${
										userState?.user?.username
									} ${intl.get('components.PersonalSetting.to-confirm-deletion')}`}
									name='self_account_deletion_confirmation'
									value={inputs.self_account_deletion_confirmation}
									onChange={(value) => handleInputChange('self_account_deletion_confirmation', value)}
								/>
								{turnstileEnabled ? (
									<Turnstile
										sitekey={turnstileSiteKey}
										onVerify={(token) => {
											setTurnstileToken(token);
										}}
									/>
								) : (
									<></>
								)}
							</div>
						</Modal>
						<Modal
							onCancel={() => setShowChangePasswordModal(false)}
							visible={showChangePasswordModal}
							size={'small'}
							centered={true}
							onOk={changePassword}
						>
							<div style={{ marginTop: 20 }}>
								<Input
									name='set_new_password'
									placeholder={intl.get('components.PersonalSetting.new-password')}
									value={inputs.set_new_password}
									onChange={(value) => handleInputChange('set_new_password', value)}
								/>
								<Input
									style={{ marginTop: 20 }}
									name='set_new_password_confirmation'
									placeholder={intl.get('components.PersonalSetting.confirm-new-password')}
									value={inputs.set_new_password_confirmation}
									onChange={(value) => handleInputChange('set_new_password_confirmation', value)}
								/>
								{turnstileEnabled ? (
									<Turnstile
										sitekey={turnstileSiteKey}
										onVerify={(token) => {
											setTurnstileToken(token);
										}}
									/>
								) : (
									<></>
								)}
							</div>
						</Modal>
					</div>
				</Layout.Content>
			</Layout>
		</div>
	);
};

export default PersonalSetting;
